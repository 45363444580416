import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Facebook from '../../../Resources/images/cya/facebook.png';
import Instagram from '../../../Resources/images/cya/instagram.png';
import Linkedin from '../../../Resources/images/cya/linkedin.png';
import Youtube from '../../../Resources/images/cya/youtube.png';
import fondo1 from '../../../Resources/images/cya/Fondo1.png';
import iconCYA2 from '../../../Resources/images/cya/header-2.png';
import './styles.scss';

export default function CyaStreaming(props) {
	return (
		<>
			<Header logo={iconCYA2} clase='bg-header-cya-streaming' />
			<div id='cya-streaming'>
				<div className='seccion-unoo'>
					<Animated animationIn='slideInLeft' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
						<img src={fondo1} alt='aw' className='full' />
					</Animated>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={7} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://vimeo.com/event/652299/embed'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={5} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='video'
										title='chat'
										src='https://vimeo.com/event/652299/chat/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>
						</Grid>
					</Container>
					<Footer instagram={Instagram} facebook={Facebook} youtube={Youtube} linkedin={Linkedin} clase='titulos-footer' />
				</div>
			</div>
		</>
	);
}

