import React, { useState } from 'react';
import firebase from '../../../Config/firebase';
import Swal from 'sweetalert2';
import { CssBaseline, TextField, Grid, Button, Container } from '@material-ui/core';
import uniqid from 'uniqid';
import { isMobile, isTablet } from 'react-device-detect';
import { validateEmail, getByValue, getDate } from '../../../scripts/functions';
import Header from '../../../Resources/images/academiaServiazgo/header-academia-serviazgo.png';
import Logo from '../../../Resources/images/academiaServiazgo/logo_login.png';
import imgDesktop from '../../../Resources/images/academiaServiazgo/img_login_desktop.png';
import imgMobile from '../../../Resources/images/academiaServiazgo/img_login_mobile.png';

// mobile
import LogoMobile1 from '../../../Resources/images/academiaServiazgo/logo_header1.png';
import LogoMobile2 from '../../../Resources/images/academiaServiazgo/logo_header2.png';

import ingresar from '../../../Resources/images/academiaServiazgo/btn_ingresar.png';
import './styles.scss';

export default function AcedemiaServiazgo(props) {
	const [data, setData] = useState({});

	async function submitForm(e) {
		e.preventDefault();
		const { usuarioSet } = props;
		const id = uniqid();
		
		if (data.id === '' || data.nombre === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
		} else {
			const resultado = await getByValue('/academiaServiazgo/ingreso/', 'id', data.id);
			const date = getDate();

			if (resultado) {
				usuarioSet(data.id);
			} else {
				try {
					await firebase.database().ref(`academiaServiazgo/ingreso/${data.id}`).set({
						...data,
						fechaIngreso: date,
					}).then(() => {
						usuarioSet(data.id);
					});
				} catch (error) {
					console.log('====================================');
					console.log('error setDataBase: ' + error);
					console.log('====================================');
				}
			}

		}
	}

	return (
		<>
			{!isMobile && <img src={Header} alt="logo" className='full' />}
			{isMobile && <>
				<Container component='main' maxWidth='lg'>
					<Grid container direction='row' alignItems='center' justify='center'>
						<Grid item sm={6} xs={12}>
							<img src={LogoMobile1} alt="logo" className='full' />
						</Grid>
						<Grid item sm={6} xs={12}>
							<div className='center'><img src={LogoMobile2} alt="logo" className='' /></div>
						</Grid>
					</Grid>
				</Container>
			</>}
			<div id='academiaServiazgo'>
				<Container component='main' maxWidth='md'>
					<CssBaseline />
					<form className='form-ingreso' noValidate onSubmit={submitForm}>
						{!isMobile && <Grid container direction='row' alignItems='stretch'>
							<Grid item lg={5} md={6} sm={12} xs={12}>
								<div style={{ backgroundImage: `url(${imgDesktop})`, backgroundPosition: 'initial', backgroundSize: 'cover', height: '100%'}}></div>
							</Grid>
							<Grid item lg={7} md={6} sm={12} xs={12}>
								<Grid container direction='row' justify='center' alignItems='center' spacing={3}>
									<Grid item lg={12} md={12} sm={12} xs={12} className='center' style={{ marginTop: '20px' }}>
										<img src={Logo} alt="asaaa" />
										<div className='titulo full center'>
											<span>Introduce tus datos</span> para ingresar al<br />
											Lanzamiento de la Academia de Serviazgo
										</div>
									</Grid>
									<Grid item lg={8} md={12} sm={12} xs={12}>
										<TextField
											fullWidth
											variant='filled'
											margin='normal'
											id='nombre'
											label='Ingresa tu número de empleado'
											value={data.id}
											onChange={(e) => setData({ ...data, id: e.target.value })}
										/>
										<TextField
											fullWidth
											variant='filled'
											margin='normal'
											id='nombre'
											label='Ingresa tu nombre'
											value={data.nombre}
											onChange={(e) => setData({ ...data, nombre: e.target.value })}
										/>
										<div className='full center cursor'>
											<Button type='submit' className='cursor'>

												<img src={ingresar} alt="ingresar" className='cursor' />
											</Button>
										</div>
									</Grid>
									<Grid item lg={12} xs={12}>
									</Grid>
								</Grid>
							</Grid>
						</Grid>}
						{isMobile && <Grid container direction='row' alignItems='stretch'>
							<Grid item sm={12} xs={12}>
								<img src={imgMobile} alt="asaaa" className='full' />
							</Grid>
							<Grid item lg={7} md={6} sm={12} xs={12} style={{ padding: '15px' }}>
								<Grid container direction='row' justify='center' alignItems='center' spacing={3}>
									<Grid item lg={12} md={12} sm={12} xs={12} className='center' style={{ marginTop: '20px' }}>
										<img src={Logo} alt="asaaa" />
										<div className='titulo full center'>
											<span>Introduce tus datos</span> para ingresar al<br />
											Lanzamiento de la Academia de Serviazgo
										</div>
									</Grid>
									<Grid item lg={8} md={12} sm={12} xs={12}>
										<TextField
											fullWidth
											variant='filled'
											margin='normal'
											id='nombre'
											label='Ingresa tu número de empleado'
											value={data.nombre}
											onChange={(e) => setData({ ...data, nombre: e.target.value })}
										/>
									</Grid>
									<Grid item lg={12} xs={12}>
										<div className='full center cursor'>
											<Button type='submit' className='cursor'>

												<img src={ingresar} alt="ingresar" className='cursor' />
											</Button>
										</div>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						}
					</form>
				</Container>
			</div>
		</>
	);
}
