import React, { useState } from 'react';
import firebase from '../../../Config/firebase';
import { CssBaseline, TextField, Grid, Button, Container, Checkbox, FormControlLabel } from '@material-ui/core';
import Swal from 'sweetalert2';
import uniqid from 'uniqid';
import { getByValue, getDate } from '../../../scripts/functions';
import Header from '../../../components/Header';
import AvisoDePrivacidad from '../../../Resources/pdf/mobi/FCA-Aviso-de-Privacidad-Distribuidores.pdf';
import LogoMobi from '../../../Resources/images/fcaMobi/header_logos.svg';
import './styles.scss';

export default function MobiLogin(props) {
	const [privacidad, setPrivacidad] = useState(false);
	const [data, setData] = useState({});

	async function submitForm(e) {
		e.preventDefault();
		const { usuarioSet } = props;
		const date = getDate();
		const resultado = await getByValue('fcamobi/usuarios', 'sid', data.sid);

		if (data.sid === '' || data.numDistribuidor === '' || data.posicion === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
		} else if (data.sid.length !== 7) {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'El SID son 7 caracteres',
			});
		} else if (data.numDistribuidor.length !== 5) {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'El Número distribuidor  son 5 caracteres',
			});
		} else if (!privacidad) {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Acepta el aviso de privacidad',
			});
		} else {
			if (resultado) {
				usuarioSet(data.sid);
			} else {
				usuarioSet(data.sid);
				try {
					await firebase.database().ref(`fcamobi/usuarios/${uniqid()}`).set({ sid: data.sid, numDistribuidor: data.numDistribuidor, posicion: data.posicion.toUppercase(), fecha: date, avisoDePrivacidad: true });
				} catch (error) {
					console.log('====================================');
					console.log('error setDataBase: ' + error);
					console.log('====================================');
				}
			}
		}
	}

	return (
		<>
			<Header logo={LogoMobi} clase='bg-header-mobi' sizeLogo={false} />
			<div id='mobi'>
				<div className='contenedor-form-mobi'>
					<Container component='main' maxWidth='lg'>
						<CssBaseline />
						<form className='form-ingreso bg-mobi' noValidate onSubmit={submitForm}>
							<div style={{ height: '130px' }}></div>
							<Grid container direction='row' spacing={5}>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<TextField fullWidth variant='outlined' margin='normal' id='sid' value={data.sid} onChange={e => setData({ ...data, sid: e.target.value })} label='SID' />
								</Grid>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<TextField fullWidth variant='outlined' margin='normal' id='numDistribuidor' value={data.numDistribuidor} onChange={e => setData({ ...data, numDistribuidor: e.target.value })} label='Número distribuidor' />
								</Grid>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<TextField fullWidth variant='outlined' margin='normal' id='posicion' value={data.posicion} onChange={e => setData({ ...data, posicion: e.target.value })} label='Posición' />
								</Grid>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<div style={{ textAlign: 'left' }}>
										<FormControlLabel
											control={<Checkbox checked={privacidad} onChange={() => setPrivacidad(!privacidad)} name='privacidad' color='primary' />}
											label=''
										/>
										<a style={{ color: '#000' }} href={AvisoDePrivacidad} rel="noreferrer" target='_blank'>
											He leído y acepto el aviso de privacidad
										</a>
									</div>
								</Grid>
								<Grid item lg={12} xs={12}>
									<div className='full center'>
										<Button type='submit' className='mt-20 center btn-login-background'>
											<span className='texto-btn-login uppercase'>entrar</span>
										</Button>
									</div>
								</Grid>
							</Grid>
						</form>
					</Container>
				</div>
			</div>
		</>
	);
}
