import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import Facebook from '../../../Resources/images/consumerCentricity/facebook.png';
import Instagram from '../../../Resources/images/consumerCentricity/instagram.png';
import Linkedin from '../../../Resources/images/consumerCentricity/linkedin.png';
import Youtube from '../../../Resources/images/consumerCentricity/youtube.png';
import fondo1 from '../../../Resources/images/consumerCentricity/Fondo1.png';
import img from '../../../Resources/images/consumerCentricity/img03.png';
import Logo from '../../../Resources/images/consumerCentricity/Logo-gris.png';
import './styles.scss';

export default function CostumerCentricityStreaming() {
	return (
		<>
			<Header logo={Logo} />
			<div id='consumer-centricity-streaming'>
				<div className='seccion-unoo'>
					<Animated animationIn='slideInLeft' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
						<img src={fondo1} alt='aw' className='full' />
					</Animated>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={12} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://player.vimeo.com/video/563780758'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='video'
										title='chat'
										src='https://vimeo.com/live-chat/563780758/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>
						</Grid>
					</Container>
					<Footer instagram={Instagram} facebook={Facebook} youtube={Youtube} linkedin={Linkedin} clase='titulos-footer' />
				</div>
			</div>
		</>
	);
}

