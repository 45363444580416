import React, { useState, useEffect } from 'react';
import Login from './Login';
import Vista from './Vista2';

export default function Index() {
    const [user, setUser] = useState(null);

    function usuarioSet(data) {
        setUser(data);
    }

    useEffect(() => {
        document.title = 'Stellantis';
    }, []);
    return <>{user === null ? <Login usuarioSet={usuarioSet} /> : <Vista usuario={user} />}</>;
}
