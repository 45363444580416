import React, { useState } from "react";
import firebase from "../../../Config/firebase";
import { CssBaseline, TextField, Grid, Button, Container, Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import uniqid from "uniqid";
import { getByValue, getDate } from "../../../scripts/functions";
import Modal from "../../../components/Modal";
import AvisoDePrivacidad from "../../../Resources/pdf/wagoneer/aviso-de-privacidad.pdf";
import popup from "../../../Resources/images/wagoneer/popup.png";
import Logo from "../../../Resources/images/wagoneer/Logo.png";
import Titulo from "../../../Resources/images/wagoneer/titulo.png";
import tituloPopup from '../../../Resources/images/wagoneer/titulo-popup.png';
import BtnCerrar from "../../../Resources/images/wagoneer/btnCerrar.png";
import "./styles.scss";

export default function PacificaLogin(props) {
  const [privacidad, setPrivacidad] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(true);
  const [inputOtro, setInputOtro] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    const { usuarioSet } = props;
    const date = getDate();
    const resultado = await getByValue("wagoneer/usuarios", "email", data.email.toLowerCase());

    if (data.posicion === "" || data.email === "" || data.celular === "") {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Existen campos vacios",
      });
    } else if (!privacidad) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Acepta el aviso de privacidad",
      });
    } else {
      if (resultado) {
        usuarioSet(data.sid);
      } else {
        usuarioSet(data.sid);
        try {
          await firebase
            .database()
            .ref(`wagoneer/usuarios/${uniqid()}`)
            .set({ sid: data.sid, codigoDistribuidor: data.codigoDistribuidor.toUpperCase(), celular: data.celular, posicion: data.posicion, email: data.email.toLowerCase(), fecha: date, avisoDePrivacidad: true });
        } catch (error) {
          console.log("====================================");
          console.log("error setDataBase: " + error);
          console.log("====================================");
        }
      }
    }
  }

  return (
    <>
      <div id="wagoneer">
        <div className="contenedor-form-wagoneer">
          <Container component="main" maxWidth="md">
            <Grid container direction="row" spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <img src={Logo} alt="title" className="full mb-60" />
              </Grid>
            </Grid>
            <CssBaseline />
            <form className="form-ingreso bg-wagoneer" noValidate onSubmit={submitForm}>
              <Grid container direction="row" spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <img src={Titulo} alt="title" className="full" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className='full left tooltip'>
                    <a data-tip data-for='global'> SID/TID </a>
                    <ReactTooltip id='global' aria-haspopup='true' role='example' place="top"
                      type="light"
                      effect="solid">
                      <p>SID *  Es tu usuario en DealerCONNECT (6 dígitos)</p>
                      <p>TID * Empleado de Stellantis</p>
                    </ReactTooltip>
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="sid" inputProps={{ maxLength: 7 }} onChange={(e) => setData({ ...data, sid: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className='full left'>

                    <a
                      data-for="codigoDistribuidor"
                      data-tip="*Comienza con M y posteriormente, 4 dígitos."
                      data-iscapture="true"
                      className='tooltip'
                    >
                      Código de distribuidor
                    </a>
                    <ReactTooltip
                      id="codigoDistribuidor"
                      place="top"
                      type="light"
                      effect="solid"
                      multiline={true}
                    />
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="codigoDistribuidor"
                    inputProps={{ maxLength: 5 }}
                    value={data.codigoDistribuidor}
                    onChange={(e) => setData({ ...data, codigoDistribuidor: e.target.value })}
                    label=""
                    SelectProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className='full left'>

                    <a
                      data-for="celular"
                      data-tip="*A diez dígitos"
                      data-iscapture="true"
                      className='tooltip'
                    >
                      *Celular
                    </a>
                    <ReactTooltip
                      id="celular"
                      place="top"
                      type="light"
                      effect="solid"
                      multiline={true}
                    />
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="celular" inputProps={{ maxLength: 10 }} value={data.celular} onChange={(e) => setData({ ...data, celular: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className='full left'>

                    <a
                      data-for="posicion"
                      data-tip="Código de distribuidor"
                      data-iscapture="true"
                      className='tooltip'
                    >
                      *Posición
                    </a>

                  </div>
                  {!inputOtro && (
                    <TextField
                      select
                      label=""
                      fullWidth
                      value={data.posicion}
                      onChange={(e) => (e.target.value === "Otro" ? setInputOtro(true) : setData({ ...data, posicion: e.target.value }))}
                      SelectProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      style={{ textAlign: "left" }}
                    >
                      <MenuItem value="Admon. Garantías">Admon. Garantías</MenuItem>
                      <MenuItem value="Asesor de Refacciones">Asesor de Refacciones</MenuItem>
                      <MenuItem value="Asesor de Servicio">Asesor de Servicio</MenuItem>
                      <MenuItem value="Asesor de Ventas">Asesor de Ventas</MenuItem>
                      <MenuItem value="Brand Champion">Brand Champion</MenuItem>
                      <MenuItem value="Gerente General">Gerente General</MenuItem>
                      <MenuItem value="Gerente de Mercadotecnia">Gerente de Mercadotecnia</MenuItem>
                      <MenuItem value="Gerente de Refacciones">Gerente de Refacciones</MenuItem>
                      <MenuItem value="Gerente de Servicio">Gerente de Servicio</MenuItem>
                      <MenuItem value="Gerente de Ventas">Gerente de Ventas</MenuItem>
                      <MenuItem value="Hostess">Hostess</MenuItem>
                      <MenuItem value="Líder Customer Experience">Líder Customer Experience</MenuItem>
                      <MenuItem value="Stellantis Staff">Stellantis Staff</MenuItem>
                      <MenuItem value="WEB Master">WEB Master</MenuItem>
                      <MenuItem value="Otro">Otro</MenuItem>
                    </TextField>
                  )}
                  {inputOtro && <TextField fullWidth variant="outlined" margin="normal" id="celular" value={data.posicion} onChange={(e) => setData({ ...data, posicion: e.target.value })} label="Otro " />}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className='full left'>

                    <a
                      data-for="main"
                      data-tip="Código de distribuidor"
                      data-iscapture="true"
                      className='tooltip'
                    >
                      *E-mail corporativo
                    </a>
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} className="" style={{ position: 'relative' }}>


                  <div style={{ textAlign: "left" }} className="padre privacidad">
                    <FormControlLabel className="hijos" control={<Checkbox checked={privacidad} onChange={() => setPrivacidad(!privacidad)} name="privacidad" style={{ color: "#ccc" }} />} label="" />
                    <a className="hijos" style={{ color: "#ccc", textDecoration: 'underline' }} href={AvisoDePrivacidad} rel="noreferrer" target="_blank">
                      *He leído y acepto el aviso de privacidad
                    </a>
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className="full center">
                    <Button type="submit" className="mt-20 center btn-login-background">
                      <span className="texto-btn-login uppercase"></span>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        </div>
      </div>
      <Modal open={modal}>
        <div className="instrucciones-wagoneer">
          <img src={tituloPopup} alt="popup" className="full mt-30" />
          <p className="bold">Para una mejor experiencia te recomendamos:</p>
          <p>
            <ul>
              <li>Preferentemente  contar  con  una  conexión  a  internet  sin
                sistemas  de  protección  especiales  (proxis,  firewalls,  u  otros)
                para evitar inconvenientes con el acceso a video.</li>
              <li>Si tu conexión es a través de una red empresarial con ciertas
                restricciones, es necesario que tengas abiertos los permisos y
                puertos relacionados con la visualización de vídeo.</li>
              <li>Hacer pruebas accediendo a páginas web que reproducen vídeos por ejemplo, Vimeo. <br />(verifica <a style={{ textDecoration: 'underline', color: 'blue'}} href='https://vimeo.com/264424855' target='_blank' rel="noreferrer">tu acceso aquí</a>). Si tienes algún inconveniente  para  ver vídeo por internet, consulta con tu departamento de IT para que te habiliten el acceso.</li>
            </ul>
          </p>

          <div className="full center mb-30">
            <Button className="mt-20 center" onClick={() => setModal(false)}>
              <img src={BtnCerrar} alt="cerrar" style={{ width: "40px" }} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
