import React from 'react';
import { Grid, Container } from '@material-ui/core';
import img from '../../../Resources/images/escuelaDeClientesPalmex/graficolanding.png';
import logo from '../../../Resources/images/escuelaDeClientesPalmex/logosheader.png';
import './styles.scss';

export default function CyaStreaming(props) {
	return (
		<>
			<div id='escuelaDeClientesPalmex-streaming'>
				<header><img src={logo} alt="logo" className='logo-header' /></header>
				<div id='seccion-uno'>
					<Container component='main' maxWidth='lg'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<img src={img} alt="img" className='full img-landing' />
							</Grid>
						</Grid>
					</Container>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={12} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://vimeo.com/event/652299/embed'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='video'
										title='chat'
										src='https://vimeo.com/event/652299/chat/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>
						</Grid>
					</Container>
					{/* <Footer instagram={Instagram} facebook={Facebook} youtube={Youtube} linkedin={Linkedin} clase='titulos-footer' /> */}
				</div>
			</div>
		</>
	);
}

