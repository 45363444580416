import React, { useState } from "react";
import firebase from "../../../Config/firebase";
import Swal from "sweetalert2";
import { CssBaseline, TextField, Grid, Button, Container } from "@material-ui/core";
import uniqid from "uniqid";
import { validateEmail, getByValue, getDate } from "../../../scripts/functions";
import Header from "../../../components/Header";
import Logo from "../../../Resources/images/bridgestoneVentas/logo.svg";
import Titulo from "../../../Resources/images/bridgestoneVentas/titulo_login.png";
import Entrar from "../../../Resources/images/bridgestoneVentas/btn_entrar.png";
import "./styles.scss";

export default function BridgestoneVentasLogin(props) {
  const [data, setData] = useState({});

  async function submitForm(e) {
    e.preventDefault();
    const { usuarioSet } = props;
    const date = getDate();
    const id = uniqid();

    if (data.nombre === "" || data.cargo === "" || data.empresa === "" || data.sucursal === "" || data.ciudad === "") {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Existen campos vacios",
      });
    } else {
      usuarioSet(data.nombre);
      try {
        await firebase.database().ref(`bridgestoneVentas/usuarios/${id}`).set({
          nombre: data.nombre.toUpperCase(),
          cargo: data.cargo.toUpperCase(),
          empresa: data.empresa.toUpperCase(),
          sucursal: data.sucursal.toUpperCase(),
          ciudad: data.ciudad.toUpperCase(),
          fecha: date,
        });
      } catch (error) {
        console.log("error setDataBase: " + error);
      }
    }
  }

  return (
    <>
      <Header logo={Logo} clase="bg-header-bridgestoneVentas" />
      <div id="bridgestoneVentas">
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <form className="form-ingreso" noValidate onSubmit={submitForm}>
            <Grid container direction="row" justify="center" alignItems="center" spacing={2} className="fondoForm">
              <Grid container direction="row" justify="center" alignItems="center" spacing={4} className="loginForm">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="image-container">
                    <img src={Titulo} alt="titulo_login" className="img-titulo" />
                  </div>
                  <div className="titulo2 full center">SESIÓN EN VIVO</div>
                  <p className="texto1 full center">Ingresa tus datos para acceder a la sesión</p>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField fullWidth variant="outlined" margin="normal" id="nombre" label="Nombre completo" value={data.nombre} onChange={(e) => setData({ ...data, nombre: e.target.value })} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField fullWidth variant="outlined" margin="normal" id="empresas" label="Distribuidor" value={data.empresa} onChange={(e) => setData({ ...data, empresa: e.target.value })} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField fullWidth variant="outlined" margin="normal" id="Sucursal" label="Sucursal" value={data.sucursal} onChange={(e) => setData({ ...data, sucursal: e.target.value })} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField fullWidth variant="outlined" margin="normal" id="Cargo" label="Puesto de trabajo" value={data.cargo} onChange={(e) => setData({ ...data, cargo: e.target.value })} />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField fullWidth variant="outlined" margin="normal" id="ciudad" label="Estado" value={data.ciudad} onChange={(e) => setData({ ...data, ciudad: e.target.value })} />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className="full center">
                    <Button type="submit" className="mt-20 center btn-login-background btn-login">
                      {/* <span className="texto-btn-login uppercase">entrar</span> */}
                      <img src={Entrar} alt="btn_entrar" style={{ width: "190px" }} />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    </>
  );
}
