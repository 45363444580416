import React, { useState, useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import firebase from 'firebase';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import PDF from '../../../Resources/pdf/bridgestone/Cierre.pdf';
import Logo from '../../../Resources/images/bridgestoneVentas/logo.svg';
import TituloStreaming from '../../../Resources/images/bridgestoneVentas/bg_1_01.png';
import Facebook from '../../../Resources/images/bridgestone/facebook.svg';
import Instagram from '../../../Resources/images/bridgestone/instagram.svg';
import Linkedin from '../../../Resources/images/bridgestone/linkedin.svg';
import Youtube from '../../../Resources/images/bridgestone/youtube.svg';
import BtnDownload from '../../../Resources/images/bridgestoneVentas/btn_info.png';
import './styles.scss';

export default function BridgestoneVentasStreaming() {
	const [activeChat, setActiveChat] = useState(true)

	useEffect(() => {
		const refConf = firebase.database().ref(`bridgestone/configuracion`);
		refConf.on('child_changed', function (snapshot) {
			setActiveChat(snapshot.val())
		});
	}, []);

	return (
		<>
      <Header logo={Logo} clase="header-bridgestoneVentas" />
			<div id='bridgestoneVentas-vista'>
				<div className='seccion-unoo'>
					<div className='full center'>
						<Animated animationIn='fadeInLeft' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
							<img src={TituloStreaming} alt='banner' className='full center' />
						</Animated>
					</div>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={7} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://player.vimeo.com/video/544703765'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							{activeChat && <Grid item lg={4} md={4} sm={5} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='abner'
										title='chat'
										src='https://vimeo.com/event/957582/chat/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>}
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<div className='full center'>
									<a href={PDF} download='descargable'><img src={BtnDownload} alt="btn" style={{ width: '350px', marginTop: '20px' }} /></a>
								</div>
							</Grid>
						</Grid>
						<Footer instagram={Instagram} facebook={Facebook} youtube={Youtube} linkedin={Linkedin} />
					</Container>
				</div>
			</div>
		</>
	);
}
