import React, { useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import { setFavicons } from '../../../scripts/functions';
import favicon from '../../../Resources/images/bettertogether/favicon-aranda-software-2021.png';
import TituloStreaming from '../../../Resources/images/bettertogether/Fondo1.png';
import Facebook from '../../../Resources/images/bettertogether/facebook.png';
import Instragram from '../../../Resources/images/bettertogether/web.png';
import Linkedin from '../../../Resources/images/bettertogether/linkedin.png';
import Youtube from '../../../Resources/images/bettertogether/youtube.png';
import './styles.scss';

export default function ArandaStreaming() {
	useEffect(() => {
		setFavicons(favicon);
	}, []);

	function ir(url) {
		window.open(url, '_blank');
	}

	return (
		<>
			<div id='bettertogether-streaming'>
				<div className='seccion-unoo' >
					<div className='full center'>
						<Animated animationIn='fadeIn' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
							<img src={TituloStreaming} alt='banner' className='full center fondo-uno' />
						</Animated>
					</div>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={7} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://player.vimeo.com/video/555254552'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={5} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='chat'
										title='chat'
										src='https://vimeo.com/live-chat/555254552/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>
						</Grid>
						<Grid item lg={12} xs={12}>
							<Animated animationIn='slideInUp' isVisible={true}>

								<div className='full center'>
									<img
										src={Facebook}
										alt='icon-red-social'
										className='icon-footer'
										onClick={() => ir('https://www.facebook.com/arandasoft/')}
									/>

									<img
										src={Linkedin}
										alt='icon-red-social'
										className='icon-footer'
										onClick={() => ir('https://www.linkedin.com/company/aranda-software-corp/')}
									/>

									<img
										src={Youtube}
										alt='icon-red-social'
										className='icon-footer'
										onClick={() => ir('https://www.youtube.com/c/ArandasoftwareCompany/videos')}
									/>

									<img
										src={Instragram}
										alt='icon-red-social'
										className='icon-footer'
										onClick={() => ir('https://arandasoft.com/')}
									/>
								</div>
							</Animated>
						</Grid>
						<Grid item lg={12} xs={12}>
							<Animated animationIn='slideInUp' isVisible={true}>
								<div className='full center' style={{ paddingBottom: '50px' }}>
									<a href='https://spira.co/' target='_blank' rel='noreferrer'>
										www.spira.co
									</a>{' '}
									- <a href='mailto:mercadeo@spira.co'>mercadeo@spira.co</a>
								</div>
							</Animated>
						</Grid>
					</Container>
				</div>
			</div>
		</>
	);
}
