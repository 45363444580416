import React, { useState } from 'react';
import firebase from '../../../Config/firebase';
import { TextField, Grid, Button } from '@material-ui/core';
import { isDesktop } from 'react-device-detect';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import { getByValue, getDate } from '../../../scripts/functions';
import logo from '../../../Resources/images/escuelaDeClientesPalmex/logosheader.png';
import logoMobile from '../../../Resources/images/escuelaDeClientesPalmex/Logoescueladeclientes.png';
import buttonImg from '../../../Resources/images/escuelaDeClientesPalmex/btningresa.png';
import './styles.scss';

export default function EscuelaDeClientesPalmexLogin(props) {
	const [data, setData] = useState({});

	async function submitForm(e) {
		e.preventDefault();
		const { usuarioSet } = props;
		const id = uniqid();
		const date = getDate();

		if (data.nombre === '' || data.correo === '' || data.area === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
			return null;
		}

		const resultado = await getByValue('escuelaDeClientesPalmex/usuarios', 'correo', data.numEmpleado);
		if (resultado) {
			usuarioSet(data.numEmpleado);
		} else {
			usuarioSet(data.numEmpleado);
			try {
				await firebase.database().ref(`escuelaDeClientesPalmex/usuarios/${id}`).set({ nombre: data.nombre.toUpperCase(), numEmpleado: data.numEmpleado.toString(), fecha: date });
			} catch (error) {
				console.log('====================================');
				console.log('error setDataBase: ' + error);
				console.log('====================================');
			}
		}
	}

	return (
		<>
			<div id='escuelaDeClientesPalmex'>
				<header><img src={logo} alt="logo" className='logo-header' /></header>
				<div className='container-login'>
					<div className='form'>
						<form className='' noValidate onSubmit={submitForm}>
							<Grid item xs={12}>
								<img src={logoMobile} alt="logo-mobile" className='logo-mobile full' />
							</Grid>
							<Grid item xs={12}>
								<TextField fullWidth variant='outlined' margin='normal' label='Nombre del negocio' value={data.nombre} onChange={e => setData({ ...data, nombre: e.target.value })} />
							</Grid>

							<Grid item xs={12}>
								<TextField fullWidth variant='outlined' margin='normal' label='No. de registro' value={data.numEmpleado} onChange={e => setData({ ...data, numEmpleado: e.target.value })} />
							</Grid>

							<div className='full center'>
								<Button type='submit' className='mt-20 center btn-login-background'>
									<img src={buttonImg} alt="btn" />
								</Button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
}
