import React, { useEffect, useState } from 'react';
import firebase from "../../../Config/firebase";
import { Grid, Container, Radio, RadioGroup, FormControlLabel, FormControl, TextField } from '@material-ui/core';
import { getByValue } from '../../../scripts/functions';
import Modal from "../../../components/Modal";
import uniqid from "uniqid";
import Swal from "sweetalert2";
import PDF from '../LCX_infografia copia_1912.pdf';
import btnInfo from "../btn_info.png";
import btnGuardar from "../btn_guardar.png";
import Header from "../../../Resources/images/stellantis/logo_stellantis.svg";
import Header2 from "../../../Resources/images/stellantis/logoicx.svg";
import { getDate, downloadFile } from '../../../scripts/functions';
import './styles.scss';

export default function ArandaStreaming({ usuario }) {
	const [modal, setModal] = useState(false);
	// ranking
	const [paso, setPaso] = useState(0);
	const [preguntas, setPreguntas] = useState({});

	const cerrar = async () => {
		if (Object.keys(preguntas).length < 3) {
			setModal(false)
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Contesta todas las preguntas',
			}).then((result) => {
				/* Read more about isConfirmed, isDenied below */
				if (result.isConfirmed) {
					setModal(true)

				}

			});
			return null;
		}
		const identificador = uniqid();
		let json = {};
		json = {
			...preguntas,
			id: identificador,
			fechaRegistro: getDate(),
			usuario
		};
		// ref.set(respuestas);

		try {
			await firebase
				.database()
				.ref(`stellantis/encuesta/${usuario}`)
				.update(json)
				.then(() => {
					setModal(false);
					Swal.fire({
						icon: 'success',
						title: '',
						html:
							'<h1 class="titulo-modal">¡Bien hecho!</h1><div class="abner">La encuesta se guardó correctamente.</div>',
					})
					downloadFile(PDF, 'infografia');
				});
		} catch (error) {
			console.log("====================================");
			console.log("error setDataBase: " + error);
			console.log("====================================");
		}
	};

	return (
		<>
			<div className="headerStellantis" style={{ background: '#eaeaea' }}>
				<img src={Header} alt="logo" className="logo1" />
				<img src={Header2} alt="logo2" className="logo2" />
			</div>
			<div id='stellantis-streaming'>
				<div className='seccion-unoo' >
					{/* 				
					<div className='full center' id='img-sec1'>
						<Animated animationIn='fadeIn' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
							<img src={TituloStreaming} alt='banner' className='full center fondo-uno' />
						</Animated>
					</div>
				 */}
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={12} sm={12} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://player.vimeo.com/video/780967522?h=0c4e159517&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={8} md={12} sm={12} xs={12} className="center">
								<img src={btnInfo} alt="btn" className='cursor' onClick={() => setModal(true)} />
							</Grid>
							{/* <Grid item lg={4} md={12} sm={12} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='chat'
										title='chat'
										src='https://vimeo.com/event/2712107/chat/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid> */}
							{/* <Grid item lg={8} md={8} sm={7} xs={12}>
								<img src={proximamente} alt="img" className="full" />
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12} className='center'>
								<Animated animationIn='slideInLeft' isVisible={true}>
									<div className="te-esperamos">
										Te esperamos
									</div>
									<div className="fecha">
										14/12/2022 12:00 hrs
									</div>
								</Animated>
							</Grid> */}
						</Grid>
					</Container>
				</div>
			</div>
			<Modal open={modal}>
				<Container maxWidth="md">
					<div className="contenedor-encuesta">
						<Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
							<Grid item lg={12} md={12} xs={12}>
								Para descargar tu infografía, ayúdanos respondiendo estas 3 preguntas y ayudarnos a mejorar los contenidos que diseñamos para ti. ¡Tus respuestas son valiosas!
							</Grid>
							<Grid item lg={12} md={12} xs={12}>
								<Grid item lg={12} md={12} xs={12}>
									1. ¿Asististe a la transmisión en vivo o visualizaste la grabación?
								</Grid>
								<FormControl>
									<RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={preguntas.preguntaUno} name="radio-buttons-group">
										<Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
											<Grid item lg={6} md={6} xs={12}>
												<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaUno: 'En Vivo' })} value="Totalmente relevante" control={<Radio />} label="En vivo" />
												<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaUno: 'Grabación' })} value="Relevante" control={<Radio />} label="Grabación" />
											</Grid>
										</Grid>
									</RadioGroup>
								</FormControl>
							</Grid>

							<Grid item lg={12} md={12} xs={12}>
								2. En escala del 1 al 10 donde 10 es la calificación más alta ¿qué calificación asignas a Jorge Rosas? Considera si la charla fue amena, clara, constructiva y si demostró dominio del tema.
								<RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
									<Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '1' })} value="1" control={<Radio />} label="1" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '2' })} value="2" control={<Radio />} label="2" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '3' })} value="3" control={<Radio />} label="3" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '4' })} value="4" control={<Radio />} label="4" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '5' })} value="5" control={<Radio />} label="5" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '6' })} value="6" control={<Radio />} label="6" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '7' })} value="7" control={<Radio />} label="7" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '8' })} value="8" control={<Radio />} label="8" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '9' })} value="9" control={<Radio />} label="9" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '10' })} value="10" control={<Radio />} label="10" labelPlacement="bottom" />
										</Grid>
									</Grid>
								</RadioGroup>
							</Grid>
							<Grid item lg={12} md={12} xs={12}>
								3. En escala del 1 al 10 donde 10 es la calificación más alta ¿la información proporcionada fue práctica y de fácil comprensión?
								<RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
									<Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '1' })} value="1" control={<Radio required />} label="1" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '2' })} value="2" control={<Radio />} label="2" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '3' })} value="3" control={<Radio />} label="3" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '4' })} value="4" control={<Radio />} label="4" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '5' })} value="5" control={<Radio />} label="5" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '6' })} value="6" control={<Radio />} label="6" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '7' })} value="7" control={<Radio />} label="7" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '8' })} value="8" control={<Radio />} label="8" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '9' })} value="9" control={<Radio />} label="9" labelPlacement="bottom" />
										</Grid>
										<Grid item lg={1} sm={1} xs={6}>
											<FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '10' })} value="10" control={<Radio />} label="10" labelPlacement="bottom" />
										</Grid>
									</Grid>
								</RadioGroup>
							</Grid>
							<Grid item lg={12} md={12} xs={12}>
								4. ¿Tienes dudas o comentarios?
								<TextField fullWidth rows={4} rowsMax={4} multiline label="" variant="outlined" onChange={(e) => setPreguntas({ ...preguntas, preguntaCuatro: e.target.value })} />
							</Grid>
							<Grid item lg={12} md={12} xs={12} className='center'>
								<div className="full">
									<div className="cursor" onClick={cerrar}>
										<img src={btnGuardar} alt="btn" className='descargar' />
									</div>
								</div>
							</Grid>
						</Grid>
					</div>
				</Container>
			</Modal>
		</>
	);
}
