import React, { useState, useEffect } from "react";
import firebase from "../../../Config/firebase";
import Swal from "sweetalert2";
import { CssBaseline, TextField, Grid, Button, Container } from "@material-ui/core";
import uniqid from "uniqid";
import { isMobile, isTablet } from "react-device-detect";
import { validateEmail, getByValue, getDate } from "../../../scripts/functions";
import Header from "../../../Resources/images/stellantis/logo_stellantis.svg";
import Header2 from "../../../Resources/images/stellantis/logoicx.svg";
import Texto1 from "../../../Resources/images/stellantis/texto_desktop.png";
import Texto2 from "../../../Resources/images/stellantis/texto_mobile.png";
import BG from "../../../Resources/images/stellantis/login_bg.png";

// mobile
import ingresar from "../../../Resources/images/stellantis/btn_ingresar.png";
import "./styles.scss";

export default function Stellantis(props) {
  const [data, setData] = useState({});
  console.log("isMobile", isMobile);

  async function submitForm(e) {
    e.preventDefault();
    const { usuarioSet } = props;
    const id = uniqid();

    if (Object.keys(data).length < 4) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Existen campos vacios",
      });
      return null;
    } else {
      const resultado = await getByValue("/stellantis/ingreso/", "codigo", data.codigo);
      const date = getDate();

      if (resultado) {
        usuarioSet(data.codigo);
      } else {
        try {
          await firebase
            .database()
            .ref(`stellantis/ingreso/${data.codigo}`)
            .set({
              ...data,
              fechaIngreso: date,
            })
            .then(() => {
              usuarioSet(data.codigo);
            });
        } catch (error) {
          console.log("====================================");
          console.log("error setDataBase: " + error);
          console.log("====================================");
        }
      }
    }
  }
  let backgroundStyles = `
  #root::after{
    background-image: url(${BG});
    margin-top: 4%
  };
  `;

  return (
    <>
      <style>{backgroundStyles}</style>

      <div className="headerStellantis">
        {/* <Grid container direction="row" alignItems="stretch">
          <Grid item lg={6}> */}
        <img src={Header} alt="logo" className="logo1" />
        {/* </Grid>
          <Grid item lg={6}> */}
        <img src={Header2} alt="logo2" className="logo2" />
        {/* </Grid>
        </Grid> */}
      </div>
      <div id="Stellantis">
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <form className="form-ingreso" noValidate onSubmit={submitForm}>
            {!isMobile && (
              <Grid container direction="row" alignItems="stretch">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="center" style={{ marginTop: "20px" }}>
                      <div className="titulo full center">
                        <img src={Texto1} alt="texto1" />
                        <p className="textLogin">Ingresa tus datos para acceder a la conferencia magistral.</p>
                      </div>
                    </Grid>
                    <Grid className="formulario" item direction="row" lg={9} md={9} sm={9} xs={9}>
                      <Grid container direction="row" alignItems="stretch" spacing={2}>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextField required fullWidth variant="filled" margin="normal" id="nombre" label="Nombre" value={data.nombre} onChange={(e) => setData({ ...data, nombre: e.target.value })} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextField required fullWidth variant="filled" margin="normal" id="puesto" label="Puesto" value={data.puesto} onChange={(e) => setData({ ...data, puesto: e.target.value })} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextField required fullWidth variant="filled" margin="normal" id="codigo" label="Código de Distribuidor" value={data.codigo} onChange={(e) => setData({ ...data, codigo: e.target.value })} />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                          <TextField required fullWidth variant="filled" margin="normal" id="correo" label="Correo Electrónico" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                        </Grid>
                      </Grid>
                      <div className="full center cursor">
                        <Button type="submit" className="cursor">
                          <img src={ingresar} alt="ingresar" className="cursor" />
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {isMobile && (
              <Grid container direction="row" alignItems="stretch">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Grid container direction="row" justify="center" alignItems="center" spacing={1}>
                    <Grid item lg={12} md={12} sm={12} xs={12} className="center" style={{ marginTop: "20px" }}>
                      <div className="titulo full center">
                        <img src={Texto2} alt="texto2" />
                        <p className="textLogin">
                          Ingresa tus datos para acceder <br /> a la conferencia magistral.
                        </p>
                      </div>
                    </Grid>
                    <Grid className="formulario" item lg={10} md={10} sm={10} xs={10}>
                      <Grid container direction="row" alignItems="stretch" spacing={2}>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField fullWidth variant="filled" margin="normal" id="nombre" label="Nombre" value={data.nombre} onChange={(e) => setData({ ...data, nombre: e.target.value })} />
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField fullWidth variant="filled" margin="normal" id="puesto" label="Puesto" value={data.puesto} onChange={(e) => setData({ ...data, puesto: e.target.value })} />
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField fullWidth variant="filled" margin="normal" id="codigo" label="Código" value={data.codigo} onChange={(e) => setData({ ...data, codigo: e.target.value })} />
                      </Grid>
                      <Grid item lg={6} md={12} sm={12} xs={12}>
                        <TextField fullWidth variant="filled" margin="normal" id="correo" label="Correo Electrónico" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} />
                      </Grid>
                      <div className="full center cursor">
                        <Button type="submit" className="cursor">
                          <img src={ingresar} alt="ingresar" className="cursor" />
                        </Button>
                      </div>
                    </Grid>
                    </Grid>
                </Grid>
              </Grid>
              </Grid>
            )}
        </form>
      </Container>
    </div>
    </>
  );
}
