
import firebase from 'firebase/app';

const firebaseConfig = {
	apiKey: "AIzaSyA4I0S1K_GIeedywzq_elfXgzRh1TrKnTg",
	authDomain: "liderazgo-exponencial.firebaseapp.com",
	databaseURL: "https://liderazgo-exponencial.firebaseio.com",
	projectId: "liderazgo-exponencial",
	storageBucket: "liderazgo-exponencial.appspot.com",
	messagingSenderId: "453999947470",
	appId: "1:453999947470:web:d8a3470f1502eae31a6a9f",
	measurementId: "G-P0P1HVDD20"
  };
  
  // Initialize Firebase
export default firebase.initializeApp(firebaseConfig);

