import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Header from '../../../components/Header';
import PdfInfo from '../../../Resources/pdf/pacifica/info.pdf';
import TituloStreaming from '../../../Resources/images/fcaPacifica/Fondo1.png';
import BtnInfo from '../../../Resources/images/fcaPacifica/btn_infografia.svg';
import LogoChrysler from '../../../Resources/images/fcaPacifica/Chrysler_logo.png';
import LogoSpira from '../../../Resources/images/fcaPacifica/spira_logo.svg';
import './styles.scss';

export default function PacificaStreaming() {
	return (
		<>
			<Header logo={LogoChrysler} clase='bg-header-pacifica' />
			<div id='pacifica-streaming'>
				<div className='seccion-unoo'>
					<div className='full center'>
						<Animated animationIn='slideInUp' isVisible={true} >
							<img src={TituloStreaming} alt='banner' className='full center' />
						</Animated>
					</div>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={12} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://vimeo.com/event/691617/embed/ebcabd4ecd'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='video'
										title='chat'
										src='https://vimeo.com/event/691617/chat/ebcabd4ecd'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className='full center'>
								<a href={PdfInfo} target='_blank' rel='noreferrer'>
									<img src={BtnInfo} alt='btn' style={{ width: '25%', marginTop: '20px' }} />
								</a>
							</div>
						</Grid>
						<Grid item lg={12} md={12} sm={12} xs={12}>
							<div className='full center'>
								<img src={LogoSpira} alt="logo" className='logo-spira' />
							</div>
						</Grid>
					</Container>
				</div>
			</div>
		</>
	);
}
