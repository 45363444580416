import React from 'react';
import ReactDOM from 'react-dom';
import GoogleAnalytics from 'react-ga';
import reportWebVitals from './reportWebVitals';
import Navigation from './Navigation';
import ConfigAnalitycs from './Config/googleAnalitycs';
import './Resources/scss/main.scss';

GoogleAnalytics.initialize(ConfigAnalitycs.googleAnalyticsID);

const App = () => {
    return <Navigation />;
};

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
