import firebase from 'firebase';

export async function getByValue(bd, key, val) {
	const refUsuarios = firebase.database().ref(`${bd}`);
	const snapshot = await refUsuarios.orderByChild(`${key}`).equalTo(val).once('value');
	const resultado = snapshot.val();
	return resultado;
}

export function getDate() {
	const today = new Date();
	const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()} ${
		today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()
	}:${today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes()}:${
		today.getSeconds() < 10 ? `0${today.getSeconds()}` : today.getSeconds()
	} ${today.getHours() < 12 ? 'am' : 'pm'}`;
	return date;
}

export function validateEmail(email) {
	const re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function setFavicons(favImg) {
	let headTitle = document.querySelector('head');
	let setFavicon = document.createElement('link');
	setFavicon.setAttribute('rel', 'shortcut icon');
	setFavicon.setAttribute('href', favImg);
	headTitle.appendChild(setFavicon);
}

export function downloadFile(file, name) {
	const link = document.createElement('a');
	link.href = `${file}`;
	link.download = `${name}`;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export function camelize(str) {
	return str.replace(/\W+(.)/g, function (match, chr) {
		return chr.toUpperCase();
	});
}
