import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import infografia from '../../../Resources/pdf/compass/infografia-compass.pdf';
// import Fondo from '../../../Resources/images/compass/Fondo.png';
import Fondo1 from '../../../Resources/images/compass/Fondo1.jpg';
import tituloDescarga from '../../../Resources/images/wagoneer/titulo-descarga.png';
import btnDescarga from '../../../Resources/images/compass/btn_info.png';
import './styles.scss';

let backgroundStyles = '';
// backgroundStyles += `#root::after { background-image: url(${Fondo}) }`;

export default function WagoneerStreaming() {
	return (
		<>
			<style>{backgroundStyles}</style>
			<div id='compass-streaming'>
				<div className='seccion-unoo'>
					<div className='full center'>
						<Animated animationIn='slideInLeft' isVisible={true} >
							<img src={Fondo1} alt='banner' className='full center' />
						</Animated>
					</div>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' spacing={1}>
							<Grid item lg={8} md={8} sm={12} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://vimeo.com/event/1338835/embed'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='video'
										title='chat'
										src='https://vimeo.com/event/1338835/chat/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
								<div>
									{/* <img src={tituloDescarga} alt="img" className='full' style={{marginTop: '40px'}} /> */}
								</div>
								<div  style={{ marginTop: '40px' }}>
									<a href={infografia} download="infografia" ><img src={btnDescarga} alt="img" className='full'  /></a>
								</div>
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
		</>
	);
}
