import React, { useState, useEffect } from 'react';
import Login from './Login';
import Vista from './Vista';

export default function Index() {
	const [user, setUser] = useState(null);

	function usuarioSet(data) {
		setUser(data);
	}

	useEffect(() => {
		document.title = 'Escuela de Clientes Palmex';
	}, []);
	return <>{user === null ? <Login usuarioSet={usuarioSet} /> : <Vista usuario={user} />}</>;
}
