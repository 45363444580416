export const gz = [
	'GZ AGS/ZAC',
	'GZ BCN',
	'GZ BCS',
	'GZ CD. JUÁREZ',
	'GZ CHIHUAHUA',
	'GZ COAHUILA',
	'GZ DURANGO',
	'GZ EDO MEX',
	'GZ GUANAJUATO',
	'GZ GUERRERO',
	'GZ HIDALGO',
	'GZ JALISCO',
	'GZ MICHOACÁN',
	'GZ MORELOS',
	'GZ NUEVO LEÓN',
	'GZ OAXACA',
	'GZ PUEBLA',
	' GZ QROO',
	'GZ QUERETARO',
	'GZ SINALOA',
	'GZ SLP',
	'GZ SONORA',
	'GZ TABASCO',
	'GZ TAMPS NTE',
	'GZ TAMPS SUR',
	'GZ VER CENTRO',
	'GZ VER NTE',
	'GZ VER SUR',
	'GZ YUCAM',
	'GZ VDM',
];

export const roles = ['Asesor / Preventa (PV, CC, CM)', 'Jefe de ventas ', 'Otros'];
