import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Facebook from '../../Resources/images/footer/facebook.png';
import Instragram from '../../Resources/images/footer/instragram.png';
import Linkedin from '../../Resources/images/footer/linkedin.png';
import Youtube from '../../Resources/images/footer/youtube.png';
import './styles.scss';

export default function Footer(props) {
	const { instagram, facebook, youtube, linkedin, clase } = props;

	function ir(url) {
		window.open(url, '_blank');
	}

	return (
		<Container component='main' maxWidth='lg'>
			<div id='footer'>
				<Grid container direction='row' justify='center' alignItems='center' spacing={1}>
					<Grid item lg={12} xs={12}>
						<Animated animationIn='slideInUp' isVisible={true}>
							<div className='full center'>
								<img
									src={facebook}
									alt='icon-red-social'
									className='icon-footer'
									onClick={() => ir('https://www.facebook.com/SpiraHabitos/?ref=br_rs')}
								/>

								<img
									src={linkedin}
									alt='icon-red-social'
									className='icon-footer'
									onClick={() => ir('https://www.linkedin.com/company/spirahabitosproductivos/')}
								/>

								<img
									src={youtube}
									alt='icon-red-social'
									className='icon-footer'
									onClick={() => ir('https://www.youtube.com/channel/UChGVsHDZuguyoJjGprlqUCQ?pbjreload=10')}
								/>

								<img
									src={instagram}
									alt='icon-red-social'
									className='icon-footer'
									onClick={() => ir('https://www.instagram.com/somos_spira/?hl=es-la')}
								/>
							</div>
						</Animated>
					</Grid>

					<Grid item lg={12} xs={12}>
						<Animated animationIn='slideInUp' isVisible={true}>
							<div className={`full center ${clase}`} style={{ paddingBottom: '50px' }}>
								<a className={`${clase}`} href='https://spira.co/' target='_blank' rel='noreferrer'>
									www.spira.co
								</a>
								&nbsp;-&nbsp;
								<a className={`${clase}`} href='mailto:mercadeo@spira.co'>mercadeo@spira.co</a>

							</div>
						</Animated>
					</Grid>
				</Grid>

			</div>
		</Container>

	);
}

Footer.defaultProps = {
	instagram: Instragram,
	facebook: Facebook,
	youtube: Youtube,
	linkedin: Linkedin,
	clase: ''
};