import React, { useState } from 'react';
import firebase from '../../../Config/firebase';
import { TextField, Grid, Button, Container } from '@material-ui/core';
import uniqid from 'uniqid';
import Swal from 'sweetalert2';
import { getByValue, getDate } from '../../../scripts/functions';
import Header from '../../../components/Header';
import CYAImage from '../../../Resources/images/cya/img-login.png';
import iconCYA2 from '../../../Resources/images/cya/header.png';
import './styles.scss';

export default function CyaLogin(props) {
	const [data, setData] = useState({});

	async function submitForm(e) {
		e.preventDefault();
		const { usuarioSet } = props;
		const id = uniqid();
		const date = getDate();

		if (data.nombre === '' || data.correo === '' || data.area === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
			return null;
		}

		const resultado = await getByValue('cya/usuarios', 'correo', data.correo);
		if (resultado) {
			usuarioSet(data.correo);
		} else {
			usuarioSet(data.correo);
			try {
				await firebase.database().ref(`cya/usuarios/${id}`).set({ nombre: data.nombre.toUpperCase(), area: data.area.toUpperCase(), correo: data.correo.toLowerCase(), fecha: date });
			} catch (error) {
				console.log('====================================');
				console.log('error setDataBase: ' + error);
				console.log('====================================');
			}
		}
	}

	return (
		<>
			<Header logo={iconCYA2} clase='bg-header-cya' />
			<div id='cya'>
				<Container component='main' maxWidth='lg'>
					<Grid container direction='row' justify='center' alignItems='stretch' spacing={5}>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<img src={CYAImage} alt='logo-login' className='full' />
						</Grid>
						<Grid item lg={6} md={6} sm={12} xs={12}>
							<form className='form-cya' noValidate onSubmit={submitForm}>
								<Grid item xs={12}>
									<TextField fullWidth variant='outlined' margin='normal' label='Nombre' value={data.nombre} onChange={e => setData({ ...data, nombre: e.target.value })} />
								</Grid>
								<Grid item xs={12}>
									<TextField fullWidth variant='outlined' margin='normal' label='Área' value={data.area} onChange={e => setData({ ...data, area: e.target.value })} />
								</Grid>
								<Grid item xs={12}>
									<TextField fullWidth variant='outlined' margin='normal' label='Correo electrónico' value={data.correo} onChange={e => setData({ ...data, correo: e.target.value })} />
								</Grid>
								<div className='full center'>
									<Button type='submit' className='mt-20 center btn-login-background'>
										<span className='btn-login uppercase'>entrar</span>
									</Button>
								</div>
							</form>
						</Grid>
					</Grid>
				</Container>
			</div>
		</>
	);
}
