import React from 'react';
import { PropTypes } from 'prop-types';
import Header from '../Header';
import Logo from '../../Resources/images/proMasterRapid2022/actividad/girar.png';
// import Telefono from '../../Resources/Images/voltearPantalla/voltear-telefono.svg';
import './style.css';

const VoltearPantalla = (props) => {
	const { children } = props;
	return (
		<>
			<div className='pantalla-oculta'>{children}</div>
			<div className='voltear-pantalla'>
				{/* <Header hideHeader clase='fondo-general' opacity={1} /> */}
				<div className={`contenedor-texto-voltear-pantalla center`}>
					<div className='imagenes'>
						<img src={Logo} alt='hombre-landscape' />
					</div>
				</div>
			</div>
		</>
	);
};

VoltearPantalla.propTypes = {
	clase: PropTypes.string,
	fondo: PropTypes.string,
	hideImage: PropTypes.bool,
};

VoltearPantalla.defaultProps = {
	hideImage: false,
};
export default VoltearPantalla;
