import React, { useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Logo from '../../Resources/images/vhd/vhd.svg';
import TituloStreaming from '../../Resources/images/vhd/titulo-del-streaming.png';
// import Facebook from '../../Resources/images/vhd/facebook.png';
// import Instragram from '../../Resources/images/vhd/instragram.png';
// import Linkedin from '../../Resources/images/vhd/linkedin.png';
// import Youtube from '../../Resources/images/vhd/youtube.png';
import './styles.scss';

export default function Vhd() {

    useEffect(() => {
        document.title = 'Liderazgo Exponencial';
    }, []);

    return (
        <>
            <Header logo={Logo} />
            <div id='vhd'>
                <div>
                    <div className='full center'>
                        <Animated animationIn='flipInX' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
                            <img src={TituloStreaming} alt='banner' className='size-img' />
                        </Animated>
                    </div>
                </div>

                <Container component='main' maxWidth='lg' id='streaming'>
                    <Grid container direction='row' justify='center' alignItems='stretch' spacing={2}>
                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <div className='contenedor-streaming'>
                                <iframe
                                    title='streaming'
                                    src='https://player.vimeo.com/video/483698684'
                                    allow='autoplay; fullscreen'
                                    allowFullScreen
                                    className='estilo-streaming'
                                ></iframe>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

               <Footer />
            </div>
        </>
    );
}
