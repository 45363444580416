import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Grid, Toolbar } from '@material-ui/core';
import './styles.scss';

const Header = (props) => {
	const { logo, scroll, clase, sizeLogo } = props;
	
	useEffect(() => {
		if(scroll){
			const header = document.getElementById('myHeader');
			const sticky = header.offsetTop;
			const scrollCallBack = window.addEventListener('scroll', () => {
				if (window.pageYOffset > sticky) {
					header.classList.add('sticky');
				} else {
					header.classList.remove('sticky');
				}
			});
			return () => {
				window.removeEventListener('scroll', scrollCallBack);
			};
		}
	}, []);

	return (
		<>
			<Toolbar className={`${clase}`} id='myHeader'>
				<Grid container direction='row' spacing={0}>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<img src={logo} alt='spira' className={sizeLogo && 'img-logos'} />
					</Grid>
				</Grid>
			</Toolbar>
		</>
	);
};

Header.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
Header.defaultProps = {
	clase: 'blanco',
	menu: true,
	scroll: false,
	sizeLogo: true,
};

export default Header;
