import React, { useState, useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import firebase from 'firebase';
import PDF from '../../../Resources/pdf/primax/DG_primax_lideresagiles_workbook21_sep.pdf';
import TituloStreaming from '../../../Resources/images/academiaServiazgo/Fondo3.png';
import Header from "../../../Resources/images/primax/Primax_logo.svg";
import BtnDownload from '../../../Resources/images/academiaServiazgo/btn_descargar.png';
import './styles.scss';

export default function AcedemiaServiazgoStreaming() {
	const [activeChat, setActiveChat] = useState(true)

	useEffect(() => {
		const refConf = firebase.database().ref(`bridgestone/configuracion`);
		refConf.on('child_changed', function (snapshot) {
			setActiveChat(snapshot.val())
		});
	}, []);

	return (
		<>
			
			<div id='academiaServiazgo-streaming'>
			<img src={Header} alt="logo" className='header' />
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={3}>
							<Grid item lg={8} md={8} sm={7} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src="https://player.vimeo.com/video/752717476?h=f3bde30769&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							{/* <Grid item lg={4} md={4} sm={5} xs={12}>
								<div className='contenedor-chat' style={{height: '400px'}}>
									<iframe
										id='abner'
										title='chat'
										src='https://vimeo.com/event/2447130/chat/'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid> */}
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<div className='full center'>
									<a href={PDF} download='descargable'><img src={BtnDownload} alt="btn" style={{ width: '350px', marginTop: '20px' }} /></a>
								</div>
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
		</>
	);
}
