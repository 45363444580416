import React from "react";
import { Grid, Container } from "@material-ui/core";
import { Animated } from "react-animated-css";
import Header from "../../../components/Header";
import Logo from "../../../Resources/images/heineken2/Logo.png";
import Fondo1 from "../../../Resources/images/heineken2/Fondo1.png";

import "./styles.scss";

export default function HeinekenStreaming(props) {
  let backgroundStyles = "";
  backgroundStyles += `#root { height: 130px }`;
  return (
    <>
      <style>{backgroundStyles}</style>

      <Header logo={Logo} clase="header-heineken" />
      {/* <div className="login">
        <JuegoConFondo fondo={Fondo}>
          <img src={I} alt="a" x={0} y={0} />
          <div  x={800} y={500}>
            <div className="contenedor-streaming" style={{width: "1200px"}}>
              <iframe width="1200" height="100" title="streaming" src="https://player.vimeo.com/video/623893217" allow="autoplay; fullscreen" allowFullScreen className="estilo-streaming video-heineken"></iframe>
            </div>
          </div>
        </JuegoConFondo>
      </div> */}

      <div id="Heineken-streaming">
        <div className="seccion-unoo">
          <Animated animationIn="slideInLeft" animationOut="fadeOut" isVisible={true} animationInDelay={1000}>
            <img src={Fondo1} alt="aw" className="full" />
          </Animated>
        </div>
        <div className="seccion-dos">
          <div className="asg">
            <Container component="main" maxWidth="lg" id="streaming">
              <Grid container direction="row" justify="center" alignItems="stretch" spacing={1}>
                <Grid item lg={7} md={7} sm={12} xs={12} style={{ border: "5px solid #4d8844 !important" }}>
                  <div className="contenedor-streaming">
                    <iframe title="streaming" src="https://player.vimeo.com/video/623893217" className="estilo-streaming" frameBorder="0" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen id="video"></iframe>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
