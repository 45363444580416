import { useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Logo from '../../Resources/images/nestle/Header.svg';
import TituloStreaming from '../../Resources/images/nestle/Grafico.png';
import Facebook from '../../Resources/images/nestle/Facebook.svg';
import Instagram from '../../Resources/images/nestle/Instagram.svg';
import Linkedin from '../../Resources/images/nestle/linkedIn.svg';
import Youtube from '../../Resources/images/nestle/youtube.svg';
import './styles.scss';

export default function Nestle() {
	useEffect(() => {
		document.title = 'Conversaciones empáticas: Cómo conectar efectivamente con mis clientes';
	}, []);

	return (
		<>
			<Header logo={Logo} />
			<div id='nestle'>
				<div className='seccion-uno'>
					<div className='full center'>
						<Animated animationIn='flipInX' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
							<Container component='main' maxWidth='lg' id='streaming'>
								<Grid container direction='row' justify='center' alignItems='stretch' spacing={2}>
									<Grid item lg={12} md={12} sm={12} xs={12}>
										<img src={TituloStreaming} alt='banner' className='titulo' />
									</Grid>
								</Grid>
							</Container>
						</Animated>
					</div>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={7} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://player.vimeo.com/video/483697134'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
						</Grid>
					</Container>
					<Footer instagram={Instagram} facebook={Facebook} youtube={Youtube} linkedin={Linkedin} />
				</div>
			</div>
		</>
	);
}