import React, { useState } from 'react';
import firebase from '../../../Config/firebase';
import Swal from 'sweetalert2';
import { CssBaseline, TextField, Grid, Button, Container } from '@material-ui/core';
import uniqid from 'uniqid';
import { validateEmail, getByValue, getDate } from '../../../scripts/functions';
import Header from '../../../components/Header';
import Logo from '../../../Resources/images/bridgestone/header.svg';
import './styles.scss';

export default function BridgestoneLogin(props) {
	const [data, setData] = useState({});

	async function submitForm(e) {
		e.preventDefault();
		const { usuarioSet } = props;
		const resultado = await getByValue('bridgestone/usuarios', 'email', data.email);
		const date = getDate();
		const id = uniqid();

		if (data.nombre === '' || data.cargo === '' || data.empresa === '' || data.sucursal === '' || data.ciudad === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
		} else {
			if (!validateEmail(data.email)) {
				Swal.fire({
					icon: 'error',
					title: '¡Error!',
					text: 'Ingresa un correo electronico valido',
				});
			} else {
				if (resultado) {
					usuarioSet(data.email);
				} else {
					usuarioSet(data.email);
					try {
						await firebase.database().ref(`bridgestone/usuarios/${id}`).set({
							nombre: data.nombre.toUppercase(),
							email: data.email.toLowercase(),
							cargo: data.cargo.toUppercase(),
							empresa: data.empresa.toUppercase(),
							sucursal: data.sucursal.toUppercase(),
							ciudad: data.ciudad.toUppercase(),
							fecha: date,
						});
					} catch (error) {
						console.log('====================================');
						console.log('error setDataBase: ' + error);
						console.log('====================================');
					}
				}
			}
		}
	}

	return (
		<>
			<Header logo={Logo} clase='bg-header-bridgestone' />
			<div id='bridgestone'>
				<Container component='main' maxWidth='lg'>
					<CssBaseline />
					<form className='form-ingreso' noValidate onSubmit={submitForm}>
						<Grid container direction='row' justify='center' alignItems='center' spacing={2}>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								<div className='titulo full center'>Vendiendo por valor</div>
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={12}>
								<TextField
									fullWidth
									variant='outlined'
									margin='normal'
									id='nombre'
									label='Nombre completo'
									value={data.nombre}
									onChange={(e) => setData({ ...data, nombre: e.target.value })}
								/>
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={12}>
								<TextField
									fullWidth
									variant='outlined'
									margin='normal'
									id='email'
									label='Correo electrónico'
									value={data.email}
									onChange={(e) => setData({ ...data, email: e.target.value })}
								/>
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={12}>
								<TextField
									fullWidth
									variant='outlined'
									margin='normal'
									id='Cargo'
									label='Cargo'
									value={data.cargo}
									onChange={(e) => setData({ ...data, cargo: e.target.value })}
								/>
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={12}>
								<TextField
									fullWidth
									variant='outlined'
									margin='normal'
									id='empresas'
									label='Empresa/Distribuidor'
									value={data.empresa}
									onChange={(e) => setData({ ...data, empresa: e.target.value })}
								/>
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={12}>
								<TextField
									fullWidth
									variant='outlined'
									margin='normal'
									id='Sucursal'
									label='Sucursal'
									value={data.sucursal}
									onChange={(e) => setData({ ...data, sucursal: e.target.value })}
								/>
							</Grid>
							<Grid item lg={4} md={6} sm={6} xs={12}>
								<TextField
									fullWidth
									variant='outlined'
									margin='normal'
									id='ciudad'
									label='Ciudad'
									value={data.ciudad}
									onChange={(e) => setData({ ...data, ciudad: e.target.value })}
								/>
							</Grid>
							<Grid item lg={12} xs={12}>
								<div className='full center'>
									<Button type='submit' className='mt-20 center btn-login-background'>
										<span className='texto-btn-login uppercase'>entrar</span>
									</Button>
								</div>
							</Grid>
						</Grid>
					</form>
				</Container>
			</div>
		</>
	);
}
