import { BrowserRouter, Route, Switch } from 'react-router-dom';
import posed, { PoseGroup } from 'react-pose';
// vistas
import Home from '../Views/Home';
import VHD from '../Views/VHD';
import Nestle from '../Views/Nestle';
import Cya from '../Views/C&A';
import FCAPacifica from '../Views/FCA-Pacifica';
import FCAMobi from '../Views/FCA-Mobi';
import Bridgestone from '../Views/Bridgestone';
import BridgestoneVentas from '../Views/BridgestoneVentas';
import BetterTogether from '../Views/Bettertogether';
import CostumerCentricity from '../Views/CostumerCentricity';
import Wagoneer from '../Views/Wagoneer';
import RamProMaster2022 from '../Views/RamProMaster2022';
import RamProMaster2022Actividad from '../Views/RamProMaster2022/Actividad';
import Compass from '../Views/Compass';
import Heineken from '../Views/Heineken';
import AcademiaServiazgo from '../Views/AcademiaServiazgo';
import Primax from '../Views/Primax';
import Stellantis from '../Views/Stellantis';
import EscuelaDeClientesPalmex from '../Views/EscuelaDeClientesPalmex';
import StellantisEncuesta from '../Views/Stellantis/Encuesta';
import Error from '../Views/Error';

const RouteContainer = posed.div({
    enter: { opacity: 1, delay: 10, beforeChildren: true },
    exit: { opacity: 0 },
});

export default function App() {
    return (
        <BrowserRouter>
            <Route
                render={({ location }) => {
                    return (
                        <>
                            <PoseGroup>
                                <RouteContainer key={location.key || location.pathname}>
                                    <Switch>
                                        {/* <Route exact path='/' component={Home} /> */}
                                        <Route exact path='/' component={VHD} />
                                        {/* activos */}
                                        <Route exact path='/academia-serviazgo' component={AcademiaServiazgo} />
                                        <Route exact path='/promaster-rapid-2022' component={RamProMaster2022} />
                                        <Route exact path='/tu-promaster-rapid-2022' component={RamProMaster2022Actividad} />
                                        <Route exact path='/linea-wagoneer' component={Wagoneer} />
                                        <Route exact path='/lanzamiento-compass2022' component={Compass} />
                                        <Route exact path='/manejo-defensivo' component={Heineken} />
                                        <Route exact path='/primax' component={Primax} />
                                        <Route exact path='/cxl' component={Stellantis} />
                                        <Route exact path='/cxl-encuesta' component={StellantisEncuesta} />
                                        <Route exact path='/bridgestone-ventas' component={BridgestoneVentas} />
                                        <Route exact path='/escueladeclientespalmex' component={EscuelaDeClientesPalmex} />
                                        {/* inactivos */}
                                        <Route exact path='/clientes/vhd' component={VHD} />
                                        <Route exact path='/clientes/nestle' component={Nestle} />
                                        <Route exact path='/clientes/cya' component={Cya} />
                                        <Route exact path='/clientes/pacifica' component={FCAPacifica} />
                                        <Route exact path='/clientes/mobi' component={FCAMobi} />
                                        <Route exact path='/clientes/bridgestone' component={Bridgestone} />
                                        <Route exact path='/clientes/bettertogether-arandasoftware' component={BetterTogether} />
                                        <Route exact path='/clientes/costumer-centricity' component={CostumerCentricity} />
                                        {/* vista de error 404 */}
                                        <Route exact component={Error} />
                                    </Switch>
                                </RouteContainer>
                            </PoseGroup>
                        </>
                    );
                }}
            />
        </BrowserRouter>
    );
};