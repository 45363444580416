import React, { useState, useEffect } from 'react';
import firebase from '../../../Config/firebase';
import { Animated } from 'react-animated-css';
import { TextField, MenuItem, Grid, Button, Container } from '@material-ui/core';
import Swal from 'sweetalert2';
import uniqid from 'uniqid';
import { getByValue, getDate } from '../../../scripts/functions';
import { gz, roles } from './data';
import './styles.scss';

export default function CostumerCentricityLogin(props) {
	const [data, setData] = useState({ })
	const [lg1, setLg1] = useState(8);
	const [lg2, setLg2] = useState(4);

	useEffect(() => {
		console.log(window.screen.width);
		if (window.screen.width < 1550) {
			setLg1(6);
		}
	}, [])

	async function submitForm(e) {
		const { usuarioSet } = props;
		e.preventDefault();
		const id = uniqid();
		const date = getDate();
		let json = { nombre: data.nombre.toUpperCase(), rol: data.rol, numSocio: data.numSocio, gerenciaZona: data.gerenciaZona, fecha: date }	
		const resultado = await getByValue('costumerCentricity/usuarios', 'numSocio', data.numSocio);
		
		if (Object.keys(data).length < 4) {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
		} else if (resultado) {
			usuarioSet(data.numSocio);
		} else {
			try {
				await firebase.database().ref(`costumerCentricity/usuarios/${id}`).set(json).then(() => {
					usuarioSet(data.numSocio);
				});
			} catch (error) {
				console.log('====================================');
				console.log('error setDataBase: ' + error);
				console.log('====================================');
			}
		}
	}

	return (
		<>
			<Animated animationIn='fadeInLeft' isVisible={true} animationInDelay={1000}>
				<div id='costumer-centricity'>
					<Container component='main' maxWidth='lg' className='mt-60'>
						<Grid container direction='row' alignItems='center' spacing={1}>
							<Grid item lg={lg1} md={lg1} sm={12} xs={12}>
							</Grid>
							<Grid item lg={lg2} md={lg2} sm={12} xs={12}>
								<form className='form-ingreso' noValidate onSubmit={submitForm}>
									<Grid item xs={12}>
										<TextField value={data.nombre} onChange={(e) => setData({ ...data, nombre: e.target.value })} required fullWidth variant='outlined' margin='normal' label='Nombre completo' />
										<TextField
											select
											label="Rol"
											value={data.rol}
											onChange={(e) => setData({ ...data, rol: e.target.value })}
											required
											fullWidth
											variant='outlined'
											// defaultValue='Asesor / Preventa (PV, CC, CM)'
										>
											{roles.map(val => (

												<MenuItem key={val} value={val}>{val}</MenuItem>
											))}
										</TextField>{/* <TextField value={data.rol} onChange={(e) => setData({ ...data, rol: e.target.value })} required fullWidth variant='outlined' margin='normal' label='Rol' /> */}
										<TextField value={data.numSocio} onChange={(e) => setData({ ...data, numSocio: e.target.value })} type='number' required fullWidth variant='outlined' margin='normal' label='Número de socio' />{/* <TextField value={data.gerenciaZona} onChange={(e) => setData({ ...data, gerenciaZona: e.target.value })} required fullWidth variant='outlined' margin='normal' label='Gerencia de zona' /> */}
										<TextField
											select
											label="Gerencia de zona"
											value={data.gerenciaZona}
											onChange={(e) => setData({ ...data, gerenciaZona: e.target.value })}
											required
											fullWidth
											variant='outlined'
											// defaultValue='GZ AGS/ZAC'
										>
											{gz.map(val => (

												<MenuItem key={val} value={val}>{val}</MenuItem>
											))}
										</TextField>
									</Grid>
									<div className='full center'>
										<Button type='submit' className='mt-20 center btn-login-background'>
											<span className='btn-login uppercase'>entrar</span>
										</Button>
									</div>
								</form>
							</Grid>
						</Grid>
					</Container>
				</div>
			</Animated>
		</>
	);
}
