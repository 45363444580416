import React, { useState, createRef, useEffect } from "react";
import { useScreenshot, createFileName } from "use-react-screenshot";
import { Grid, Container, Button, TextField } from "@material-ui/core";
import { Animated } from "react-animated-css";
import useWindowOrientation from "use-window-orientation";
import Modal from "../../../components/Modal";
import VoltearPantalla from "../../../components/VoltearPantalla";
import Fondo from "../../../Resources/images/proMasterRapid2022/actividad/fondo1.png";
import blanca from "../../../Resources/images/proMasterRapid2022/actividad/blanca.png";
import colorUno from "../../../Resources/images/proMasterRapid2022/actividad/color-uno.png";
import colorDos from "../../../Resources/images/proMasterRapid2022/actividad/color-dos.png";
import colorTres from "../../../Resources/images/proMasterRapid2022/actividad/color-tres.png";
import colorCuatro from "../../../Resources/images/proMasterRapid2022/actividad/color-cuatro.png";
import colorCinco from "../../../Resources/images/proMasterRapid2022/actividad/color-cinco.png";
import colorSeis from "../../../Resources/images/proMasterRapid2022/actividad/color-seis.png";
import colorSiete from "../../../Resources/images/proMasterRapid2022/actividad/color-siete.png";
import colorOcho from "../../../Resources/images/proMasterRapid2022/actividad/color-ocho.png";
import Guardar from "../../../Resources/images/proMasterRapid2022/actividad/btnGuardar.png";
import popup from "../../../Resources/images/proMasterRapid2022/actividad/popup.png";
import BtnCerrar from "../../../Resources/images/proMasterRapid2022/btnCerrar.png";
import whatsapp from "../../../Resources/images/proMasterRapid2022/actividad/whatsapp.png";
import correo from "../../../Resources/images/proMasterRapid2022/actividad/correo.png";


import "./styles.scss";

export default function ProMasterRapidActividad() {
  const { orientation } = useWindowOrientation();

  console.log(orientation);
  const [color, setColor] = useState(0);
  const [texto, setTexto] = useState("");
  const [nombre, setNombre] = useState("");
  const [modal, setModal] = useState(true);
  const [colorText, setColorText] = useState("#000");

  useEffect(() => {
    document.title = "Promaster rapid 2022 Actividad";
    // if(orientation === 'landscape') {
    //   setModal(true)
    // }
  }, []);

  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (image, { name = "img", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () => takeScreenShot(ref.current).then(download);

  const cualColor = () => {
    let camioneta = "";
    switch (color) {
      case 0:
        camioneta = blanca;
        break;
      case 1:
        camioneta = colorUno;
        break;
      case 2:
        camioneta = colorDos;
        break;
      case 3:
        camioneta = colorTres;
        break;
      case 4:
        camioneta = colorCuatro;
        break;
      case 5:
        camioneta = colorCinco;
        break;
      case 6:
        camioneta = colorSeis;
        break;
      case 7:
        camioneta = colorSiete;
        break;
      case 8:
        camioneta = colorOcho;
        break;

      default:
        camioneta = blanca;
        break;
    }
    return camioneta;
  };

  return (
    <>
      {/* <style>{backgroundStyles}</style> */}
      <VoltearPantalla>
        <div id="promasterrapid-actividad">
          <div className="seccion-unoo">
            {/* <div className="full center" style={{backgroundImage: `url(${Fondo})`}}>
            <Animated animationIn="slideInUp" isVisible={true}>
              <img src={Fondo} alt="banner" className="full center" />
            </Animated>
          </div> */}
          </div>
          <div className="seccion-dos">
            <Container component="main" maxWidth="lg" id="streaming">
              <Grid container direction="row" alignContent="center" justify="center" spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="contenedor-actividad center">
                    <div ref={ref} className="asa">
                      <label className="nombre">{nombre}</label>
                      <img src={cualColor()} alt="camioneta" />
                      <label style={{ color: `${colorText}` }}>{texto}</label>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Container>
            <Container component="main" maxWidth="lg" id="streaming">
              <Grid container direction="row" alignContent="center" justify="center" spacing={1}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div className="center">
                    <TextField fullWidth onChange={(e) => setTexto(e.target.value)} placeholder="Ingresa el texto aqui" />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div className="center">
                    {/* <input  type="color" onChange={(e) => setColor(e.target.value)} value={color} /> */}
                    <TextField fullWidth type="color" onChange={(e) => setColorText(e.target.value)} />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div className="center">
                    <TextField fullWidth onChange={(e) => setNombre(e.target.value)} placeholder="Ingresa tu nombre" />
                  </div>
                </Grid>
              </Grid>
            </Container>
            <Container component="main" maxWidth="lg" id="streaming">
              <Grid container direction="row" alignContent="center" justify="center" spacing={1}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <div className="contenedor-colores center">
                    <div className={`btn-color colorUno ${color === 1 && "border"}`} onClick={() => setColor(1)} />
                    <div className={`btn-color colorDos ${color === 2 && "border"}`} onClick={() => setColor(2)} />
                    <div className={`btn-color colorTres ${color === 3 && "border"}`} onClick={() => setColor(3)} />
                    <div className={`btn-color colorCuatro ${color === 4 && "border"}`} onClick={() => setColor(4)} />
                    <div className={`btn-color colorCinco ${color === 5 && "border"}`} onClick={() => setColor(5)} />
                    <div className={`btn-color colorSeis ${color === 6 && "border"}`} onClick={() => setColor(6)} />
                    <div className={`btn-color colorSiete ${color === 7 && "border"}`} onClick={() => setColor(7)} />
                    <div className={`btn-color colorOcho ${color === 8 && "border"}`} onClick={() => setColor(8)} />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <div className="center full cursor">
                    <img src={Guardar} alt="btn" onClick={downloadScreenshot} className="full" />
                  </div>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Button className="mt-20 center">
                    <a href={`https://wa.me/525548722982?text=Hola soy: ${nombre}, envio mi promaster rapid personalizada`} target="_blank" rel="noreferrer">
                      <img src={whatsapp} alt="cerrar" className="full" />
                    </a>
                  </Button>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Button className="mt-20 center">
                    <a href="mailto:rteja@spira.com.mx">
                      <img src={correo} alt="cerrar" className="full" />
                    </a>
                  </Button>
                </Grid>
                {/* <Grid item lg={6} md={6} sm={4} xs={4}>
                  <Button className="mt-20 center" onClick={descargarInfo}>
                    <img src={descarga} alt="cerrar" className="btn-img" />
                  </Button>
                </Grid> */}
                {/* <div className="full center mb-30">
                    <Button className="mt-20 center">
                      <a href={`https://wa.me/525548722982?text=Hola soy: ${nombre}, envio mi promaster rapid personalizada`} target="_blank" rel="noreferrer">
                        <img src={whatsapp} alt="cerrar" />
                      </a>
                    </Button>
                    <Button className="mt-20 center">
                      <a href="mailto:rteja@spira.com.mx">
                        <img src={correo} alt="cerrar" />
                      </a>
                    </Button>
                    <Button className="mt-20 center" onClick={descargarInfo}>
                      <img src={descarga} alt="cerrar" />
                    </Button>
                  </div> */}
                {/* </Grid> */}
              </Grid>
            </Container>
          </div>
        </div>

        {orientation === "landscape" && (
          <Modal open={modal}>
            <div className="instrucciones-pro-master-rapid">
              <div className="full center mb-30" style={{textAlign: 'right'}}>
                <Button className="mt-20 center" onClick={() => setModal(false)}>
                  <img src={BtnCerrar} alt="cerrar" style={{ width: "40px" }} />
                </Button>
              </div>
              <img src={popup} alt="popup" className="full mt-30" />

            </div>
          </Modal>
        )}
      </VoltearPantalla>
    </>
  );
}
