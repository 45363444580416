import React, { useState } from "react";
import firebase from "../../../Config/firebase";
import { CssBaseline, TextField, Grid, Button, Container, Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import uniqid from "uniqid";
import { getByValue, getDate } from "../../../scripts/functions";
import Modal from "../../../components/Modal";
import AvisoDePrivacidad from "../../../Resources/pdf/wagoneer/aviso-de-privacidad.pdf";
import popup from "../../../Resources/images/compass/popup01.png";
import Logo from "../../../Resources/images/compass/compass_logo.png";
import Titulo from "../../../Resources/images/compass/titulo.png";
import tituloPopup from "../../../Resources/images/compass/compass_logo.png";
import BtnCerrar from "../../../Resources/images/compass/btn_popup.png";
// proximamente
import Logos from "../../../Resources/images/compass/proximamente/logo.png";
import img1 from "../../../Resources/images/compass/proximamente/img01.png";
import img3 from "../../../Resources/images/compass/proximamente/img03.png";
import img2 from "../../../Resources/images/compass/proximamente/img02.png";

import "./styles.scss";

export default function PacificaLogin(props) {
  const [privacidad, setPrivacidad] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(true);
  const [inputOtro, setInputOtro] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    const { usuarioSet } = props;
    const date = getDate();
    const resultado = await getByValue("compass/usuarios", "email", data.email.toLowerCase());

    if (data.posicion === "" || data.email === "" || data.celular === "") {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Existen campos vacios",
      });
    } else if (!privacidad) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Acepta el aviso de privacidad",
      });
    } else {
      if (resultado) {
        usuarioSet(data.sid);
      } else {
        usuarioSet(data.sid);
       
        try {
          await firebase
            .database()
            .ref(`compass/usuarios/${uniqid()}`)
            .set({ sid: data.sid.toUpperCase(), codigoDistribuidor: data.codigoDistribuidor.toUpperCase(), posicion: data.posicion, email: data.email.toLowerCase(), fecha: date, avisoDePrivacidad: true });
        } catch (error) {
          console.log("====================================");
          console.log("error setDataBase: " + error);
          console.log("====================================");
        }
      }
    }
  }

  return (
    <>
      <div className="full">
        <img src={Logos} alt="logo" style={{ padding: "10px 0 0 10px" }} />
      </div>
      <div id="compass">
        {/* <Container component="main" maxWidth="md">
          <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
            <div className="center-vertical">
              <Grid item lg={8} md={8} sm={8} xs={12}>
                <img src={img1} alt="img" className="full" />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12}>
                <div className="full center">
                  <img src={img3} alt="img" className="" />
                  <img src={img2} alt="img" className="" />
                </div>
              </Grid>
            </div>
          </Grid>
        </Container> */}
        <div className="contenedor-form-compass">
          <Container component="main" maxWidth="md">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <img src={Logo} alt="title" className="full mb-60" />
            </Grid>

            <CssBaseline />
            <form className="form-ingreso bg-compass" noValidate onSubmit={submitForm}>
              <Grid container direction="row" spacing={2}>
                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <img src={Titulo} alt="title" className="full" />
                </Grid> */}
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left tooltip">
                    <a data-tip data-for="global" className="tooltip">
                      {" "}
                      SID/TID{" "}
                    </a>
                    <ReactTooltip id="global" aria-haspopup="true" role="example" place="top" type="dark" effect="solid">
                      <p>SID * Es tu usuario en DealerCONNECT (6 dígitos)</p>
                      <p>TID * Empleado de Stellantis</p>
                    </ReactTooltip>
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="sid" inputProps={{ maxLength: 7 }} onChange={(e) => setData({ ...data, sid: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="codigoDistribuidor" data-tip="*Comienza con M y posteriormente, 4 dígitos." data-iscapture="true" className="tooltip">
                      Código de distribuidor
                    </a>
                    <ReactTooltip id="codigoDistribuidor" place="top" type="light" effect="solid" multiline={true} />
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="codigoDistribuidor"
                    inputProps={{ maxLength: 5 }}
                    value={data.codigoDistribuidor}
                    onChange={(e) => setData({ ...data, codigoDistribuidor: e.target.value })}
                    label=""
                    SelectProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="posicion" data-tip="Código de distribuidor" data-iscapture="true" className="tooltip">
                      *Posición
                    </a>
                  </div>
                  {!inputOtro && (
                    <TextField
                      select
                      label=""
                      fullWidth
                      value={data.posicion}
                      onChange={(e) => (e.target.value === "Otro" ? setInputOtro(true) : setData({ ...data, posicion: e.target.value }))}
                      SelectProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      style={{ textAlign: "left" }}
                    >
                      <MenuItem value="Admon. Garantías">Admon. Garantías</MenuItem>
                      <MenuItem value="Asesor de Refacciones">Asesor de Refacciones</MenuItem>
                      <MenuItem value="Asesor de Servicio">Asesor de Servicio</MenuItem>
                      <MenuItem value="Asesor de Ventas">Asesor de Ventas</MenuItem>
                      <MenuItem value="Brand Champion">Brand Champion</MenuItem>
                      <MenuItem value="Gerente General">Gerente General</MenuItem>
                      <MenuItem value="Gerente de Mercadotecnia">Gerente de Mercadotecnia</MenuItem>
                      <MenuItem value="Gerente de Refacciones">Gerente de Refacciones</MenuItem>
                      <MenuItem value="Gerente de Servicio">Gerente de Servicio</MenuItem>
                      <MenuItem value="Gerente de Ventas">Gerente de Ventas</MenuItem>
                      <MenuItem value="Hostess">Hostess</MenuItem>
                      <MenuItem value="Líder Customer Experience">Líder Customer Experience</MenuItem>
                      <MenuItem value="Stellantis Staff">Stellantis Staff</MenuItem>
                      <MenuItem value="WEB Master">WEB Master</MenuItem>
                      <MenuItem value="Otro">Otro</MenuItem>
                    </TextField>
                  )}
                  {inputOtro && <TextField fullWidth variant="outlined" margin="normal" id="celular" value={data.posicion} onChange={(e) => setData({ ...data, posicion: e.target.value })} label="Otro " />}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="main" data-tip="Código de distribuidor" data-iscapture="true" className="tooltip">
                      *E-mail corporativo
                    </a>
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div style={{ textAlign: "left" }} className="padre">
                    <FormControlLabel className="hijos" control={<Checkbox checked={privacidad} onChange={() => setPrivacidad(!privacidad)} name="privacidad" style={{ color: "#ccc" }} />} label="" />
                    <a className="hijos" style={{ color: "#46484d", textDecoration: "underline" }} href={AvisoDePrivacidad} rel="noreferrer" target="_blank">
                      *He leído y acepto el aviso de privacidad
                    </a>
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className="full center">
                    <Button type="submit" className="mt-20 center btn-login-background">
                      <span className="texto-btn-login uppercase"></span>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        </div>
      </div>
      <Modal open={modal}>
        <div className="instrucciones-compass">
          <div className="center full">
            <img src={tituloPopup} alt="popup" className="mt-30" style={{ width: "400px" }} />
          </div>
          <p className="bold full center">
            ¡Gracias por estar aquí!
            <br />
            Para una mejor experiencia te recomendamos
          </p>
          <p style={{paddingRight: '30px', textAlign: 'justify'}}>
            <ul>
              <li>Preferentemente contar con una conexión a internet sin sistemas de protección especiales (proxis, firewalls, u otros) para evitar inconvenientes con el acceso a video.</li>
              <li>Si tu conexión es a través de una red empresarial con ciertas restricciones, es necesario que tengas abiertos los permisos y puertos relacionados con la visualización de vídeo.</li>
              <li>
                Hacer pruebas accediendo a páginas web que reproducen vídeos por ejemplo, Vimeo
                (verifica{" "}
                <a style={{ textDecoration: "underline", color: "blue" }} href="https://vimeo.com/264424855" target="_blank" rel="noreferrer">
                  tu acceso aquí
                </a>
                ) si tienes algún inconveniente para ver vídeo por internet, consulta con tu departamento de IT para que te habiliten el acceso.
              </li>
            </ul>
          </p>

          <div className="full center mb-30">
            <Button className="mt-20 center" onClick={() => setModal(false)}>
              <img src={BtnCerrar} alt="cerrar" style={{ width: "200px" }} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
