import React, { useState } from "react";
import firebase from "../../../Config/firebase";
import Swal from "sweetalert2";
import { Grid, Container, Radio, RadioGroup, FormControlLabel, FormControl, TextField } from '@material-ui/core';
import uniqid from "uniqid";
import { downloadFile, getDate } from "../../../scripts/functions";
import Header from "../../../Resources/images/stellantis/logo_stellantis.svg";
import Header2 from "../../../Resources/images/stellantis/logoicx.svg";
import PDF from '../LCX_infografia copia_1912.pdf';
import btnGuardar from "../btn_guardar.png";
import BG from "../../../Resources/images/stellantis/login_bg.png";
import "./styles.scss";

export default function StellantisEncuesta() {
  const [preguntas, setPreguntas] = useState({});

  const cerrar = async () => {
    if (Object.keys(preguntas).length < 3) {

      Swal.fire({
        icon: 'error',
        title: '¡Error!',
        text: 'Contesta todas las preguntas',
      });
      return null;
    }
    const identificador = uniqid();
    let json = {};
    json = {
      ...preguntas,
      id: identificador,
      fechaRegistro: getDate(),
    };
    // ref.set(respuestas);

    try {
      await firebase
        .database()
        .ref(`stellantis/encuesta/${identificador}`)
        .update(json)
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: '',
            html:
              '<h1 class="titulo-modal">¡Bien hecho!</h1><div class="abner">La encuesta se guardó correctamente.</div>',
            text: '',
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              window.location.reload()
            }
          });
          downloadFile(PDF, 'infografia');
        });
    } catch (error) {
      console.log("====================================");
      console.log("error setDataBase: " + error);
      console.log("====================================");
    }
  };
  let backgroundStyles = `
  #root::after{
    background-image: url(${BG});
    margin-top: 4%
  };
  `;

  return (
    <>
      <style>{backgroundStyles}</style>

      <div className="headerStellantis">
        {/* <Grid container direction="row" alignItems="stretch">
          <Grid item lg={6}> */}
        <img src={Header} alt="logo" className="logo1" />
        {/* </Grid>
          <Grid item lg={6}> */}
        <img src={Header2} alt="logo2" className="logo2" />
        {/* </Grid>
        </Grid> */}
      </div>
      <div id="Stellantis">
        <form noValidate onSubmit={cerrar} style={{ paddingBottom: '40px' }}>
          <Container maxWidth="md">
            <div className="contenedor-encuesta">
              <Grid container direction="row" alignItems="center" justifyContent="center" spacing={3}>
                <Grid item lg={12} md={12} xs={12}>
                  Para descargar tu infografía, ayúdanos respondiendo estas 3 preguntas y ayudarnos a mejorar los contenidos que diseñamos para ti. ¡Tus respuestas son valiosas!
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  1. Por favor ingresa tu correo:
                  <TextField fullWidth label="" required type="email" variant="outlined" onChange={e => setPreguntas({ ...preguntas, usuario: e.target.value })} />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Grid item lg={12} md={12} xs={12}>
                    2. ¿Asististe a la transmisión en vivo o visualizaste la grabación?
                  </Grid>
                  <FormControl>
                    <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue={preguntas.preguntaDos} name="radio-buttons-group">
                      <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                        <Grid item lg={6} md={6} xs={12}>
                          <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaUno: 'En Vivo' })} value="Totalmente relevante" control={<Radio />} label="En vivo" />
                          <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaUno: 'Grabación' })} value="Relevante" control={<Radio />} label="Grabación" />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  3. En escala del 1 al 10 donde 10 es la calificación más alta ¿qué calificación asignas a Jorge Rosas? Considera si la charla fue amena, clara, constructiva y si demostró dominio del tema.
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '1' })} value="1" control={<Radio />} label="1" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '2' })} value="2" control={<Radio />} label="2" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '3' })} value="3" control={<Radio />} label="3" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '4' })} value="4" control={<Radio />} label="4" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '5' })} value="5" control={<Radio />} label="5" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '6' })} value="6" control={<Radio />} label="6" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '7' })} value="7" control={<Radio />} label="7" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '8' })} value="8" control={<Radio />} label="8" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '9' })} value="9" control={<Radio />} label="9" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaDos: '10' })} value="10" control={<Radio />} label="10" labelPlacement="bottom" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  4. En escala del 1 al 10 donde 10 es la calificación más alta ¿la información proporcionada fue práctica y de fácil comprensión?
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                    <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '1' })} value="1" control={<Radio required />} label="1" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '2' })} value="2" control={<Radio />} label="2" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '3' })} value="3" control={<Radio />} label="3" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '4' })} value="4" control={<Radio />} label="4" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '5' })} value="5" control={<Radio />} label="5" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '6' })} value="6" control={<Radio />} label="6" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '7' })} value="7" control={<Radio />} label="7" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '8' })} value="8" control={<Radio />} label="8" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '9' })} value="9" control={<Radio />} label="9" labelPlacement="bottom" />
                      </Grid>
                      <Grid item lg={1} sm={1} xs={6}>
                        <FormControlLabel onChange={() => setPreguntas({ ...preguntas, preguntaTres: '10' })} value="10" control={<Radio />} label="10" labelPlacement="bottom" />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  5. ¿Tienes dudas o comentarios?
                  <TextField fullWidth rows={4} rowsMax={4} multiline label="" variant="outlined" onChange={(e) => setPreguntas({ ...preguntas, preguntaCuatro: e.target.value })} />
                </Grid>
                <Grid item lg={12} md={12} xs={12} className='center'>
                  <div className="full">
                    <div className="cursor" onClick={cerrar}>
                      <img src={btnGuardar} alt="btn" className='descargar' />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </form>
      </div>
    </>
  );
}
