import React from 'react';
import { Grid, Container } from '@material-ui/core';
import { Animated } from 'react-animated-css';
import Header from '../../../components/Header';
import PdfInfo from '../../../Resources/pdf/mobi/infografiaMOBI.pdf';
import PdfManejo from '../../../Resources/pdf/mobi/Manejoobjeciones.pdf';
import LogoMobi from '../../../Resources/images/fcaMobi/header_logos.svg';
import MobiLogo from '../../../Resources/images/fcaMobi/mobi_logo.svg';
import imgSeccion1 from '../../../Resources/images/fcaMobi/img01.png';
import BtnInfo from '../../../Resources/images/fcaMobi/btn-info.svg';
import BtnManejo from '../../../Resources/images/fcaMobi/btn-manejo.svg';
import SPIRALOGO from '../../../Resources/images/fcaPacifica/spira_logo.svg';
import './styles.scss';

export default function MobiStreaming() {
	return (
		<>
			<Header logo={LogoMobi} clase='bg-header-pacifica' sizeLogo={false} />
			<div id='pacifica-streaming'>
				<div className='seccion-unoo'>
					<div className='full center'>
						<Animated animationIn='slideInLeft' animationOut='fadeOut' isVisible={true} animationInDelay={1000}>
							<img src={imgSeccion1} alt='banner' className='full center' />
						</Animated>
					</div>
				</div>
				<div className='seccion-dos'>
					<Container component='main' maxWidth='lg' id='streaming'>
						<Grid container direction='row' justify='center' alignItems='stretch' spacing={1}>
							<Grid item lg={8} md={8} sm={12} xs={12}>
								<div className='contenedor-streaming'>
									<iframe
										title='streaming'
										src='https://vimeo.com/event/691617/embed/ebcabd4ecd'
										allow='autoplay; fullscreen'
										allowFullScreen
										className='estilo-streaming'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={12} xs={12}>
								<div className='contentenedor-chat'>
									<iframe
										id='video'
										title='chat'
										src='https://vimeo.com/event/691617/chat/ebcabd4ecd'
										width='100%'
										height='100%'
										frameBorder='0'
									></iframe>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={6} xs={12}>
								<div className='full center'>
									<a href={PdfInfo} target='_blank' rel='noreferrer'>
										<img src={BtnInfo} alt='btn' style={{ width: '100%' }} />
									</a>
								</div>
							</Grid>
							<Grid item lg={4} md={4} sm={6} xs={12}>
								<div className='full center'>
									<a href={PdfManejo} target='_blank' rel='noreferrer'>
										<img src={BtnManejo} alt='btn' style={{ width: '100%' }} />
									</a>
								</div>
							</Grid>
						</Grid>
					</Container>
					<div className='mobi-footer'>
						<Container component='main' maxWidth='lg' id='streaming'>
							<Grid container direction='row' justify='center' alignItems='center' spacing={1}>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<div className='full center'>

										<a style={{ fontSize: '47px !important' }} href='https://spira.co/' target='_blank' rel='noreferrer'>
											<img src={SPIRALOGO} alt='spira-logo' style={{ width: '40%' }} />
										</a>
									</div>
								</Grid>
								<Grid item lg={6} md={6} sm={6} xs={12}>
									<div className='full center'>
										<img src={MobiLogo} alt='mobi-logo' style={{ width: '40%' }} />
									</div>
								</Grid>
							</Grid>
						</Container>
					</div>
				</div>
			</div>
		</>
	);
}
