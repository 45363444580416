import React from 'react';
import { Grid, Container } from '@material-ui/core';
import ErrorImg from '../../Resources/images/error/Grafico.png';
import BtnRegresar from '../../Resources/images/error/Boton-regresar.svg';
import Intagram from '../../Resources/images/error/Instagram.svg';
import LinkedIn from '../../Resources/images/error/LinkedIn.svg';
import Youtube from '../../Resources/images/error/Youtube.svg';
import './style.scss';

export default function Error() {
	function ir(url) {
		window.open(url, '_blank');
	}

	return (
		<div id='container-error' className='mt-60'>
			<Container component='main' maxWidth='lg'>
				<Grid
					container
					direction="row"
					justify="center"
					alignItems="center"
				>
					<Grid item xs={12}>
						<div className='full center'>
							<img src={ErrorImg} alt='img' style={{ width: '85%' }} />
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className='full center'>
							<img src={BtnRegresar} alt='img' className='btn-regresar' onClick={() => window.history.back()} />
						</div>
					</Grid>
					<Grid item xs={12}>
						<div className='full center'>
							<img
								src={Youtube}
								alt='icon-red-social'
								className='icon-footer'
								onClick={() => ir('https://www.youtube.com/channel/UChGVsHDZuguyoJjGprlqUCQ?pbjreload=10')}
							/>

							<img
								src={LinkedIn}
								alt='icon-red-social'
								className='icon-footer'
								onClick={() => ir('https://www.linkedin.com/company/spirahabitosproductivos/')}
							/>

							<img src={Intagram} alt='icon-red-social' className='icon-footer' onClick={() => ir('https://www.instagram.com/somos_spira/?hl=es-la')} />
						</div>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}
