const dominios = [
    'outlook.com', 
    'outlook', 
    'OUTLOOK',
    'OUTLOOK.com',
    'OUTLOOK.COM',
    'hotmail.com', 
    'hotmail', 
    'HOTMAIL',
    'HOTMAIL.com',
    'HOTMAIL.COM',
    'gmail.com',
    'gmail',
    'GMAIL.com',
    'GMAIL',
    'GMAIL.COM',
    'yahoo.com',
    'yahoo',
    'YAHOO',
    'YAHOO.com',
    'YAHOO.COM',
    'aol.com',
    'aol',
    'AOL',
    'AOL.com',
    'AOL.COM',
];
export default dominios;
