import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import firebase from '../../../Config/firebase';
import uniqid from 'uniqid';
import { CssBaseline, TextField, Grid, Container } from '@material-ui/core';
import { getByValue, getDate, setFavicons } from '../../../scripts/functions';
import Dominios from '../dominios';
import Logo from '../../../Resources/images/bettertogether/Logos.png';
import Img from '../../../Resources/images/bettertogether/img01.png';
import favicon from '../../../Resources/images/bettertogether/favicon-aranda-software-2021.png';
import './styles.scss';

export default function ArandaLogin(props) {
	const [registro, setRegistro] = useState(false);
	const [data, setData] = useState({ nombre: '', email: '', empresaCanal: '', pais: '', cargo: '' });
	const { usuarioSet } = props;

	useEffect(() => {
		// console.log(window.screen.width);
		if (window.screen.width > 1550) {
			const container = document.getElementById('container-info');
			container.classList.add('as');
		}
		setFavicons(favicon);
	}, []);

	async function submitForm(e) {
		e.preventDefault();
		const resultado = await getByValue('bettertogether/usuarios', 'email', data.email);
		const date = getDate();

		if (data.nombre === '' || data.email === '' || data.empresaCanal === '' || data.pais === '' || data.cargo === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
		} else {
			const dominioIngresado = data.email.split('@');
			if (Dominios.indexOf(dominioIngresado[1]) === -1) {
				if (resultado) {
					Swal.fire({
						icon: 'success',
						title: '',
						text: 'Este mail ya está registrado.',
					});
				} else {
					try {
						await firebase
							.database()
							.ref(`bettertogether/usuarios/${uniqid()}`)
							.set({
								nombre: data.nombre.toUppercase(),
								email: data.email.toLowercase(),
								cargo: data.cargo.toUppercase(),
								empresa: data.empresaCanal.toUpperCase(),
								pais: data.pais.toUpperCase(),
								fecha: date,
							})
							.then(() => {
								Swal.fire({
									icon: 'success',
									title: '¡Gracias!',
									text: 'Abre el archivo que se descargó para agendar el evento en tu calendario.',
								}).then((result) => {
									if (result.isConfirmed) {
										usuarioSet(data.email);
									}
								});
							});
					} catch (error) {
						console.log('====================================');
						console.log('error setDataBase: ' + error);
						console.log('====================================');
					}
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: '¡Error!',
					text: 'Para registrarte necesitas ingresar un correo corporativo.',
				});
			}
		}
	}

	const ChecarEmail = async (e) => {
		e.preventDefault();
		if (data.email === '') {
			Swal.fire({
				icon: 'error',
				title: '¡Error!',
				text: 'Existen campos vacios',
			});
		} else {
			const resultado = getByValue('bettertogether/usuarios', 'email', data.email)
			if (resultado) {
				usuarioSet(data.email);
			} else {
				Swal.fire({
					icon: 'error',
					title: '¡Lo sentimos!',
					text: 'Regístrate para poder acceder',
					confirmButtonText: 'Regístrate',
					confirmButtonColor: '#fa967b',
				}).then((result) => {
					setRegistro(!registro);
				});
			}
		}
	};

	return (
		<>
			<div id='bettertogether'>
				<div id='container-info'>
					<Container component='main' maxWidth='lg'>
						<CssBaseline />

						{registro ? (
							<>
								<form id='form' className='form-ingreso' noValidate onSubmit={submitForm}>
									<Grid container direction='row' justify='center' alignItems='stretch' spacing={3}>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<div className='full center'>
												<img src={Logo} className='logoss' alt='logo' style={{ marginBottom: '30px' }} />
											</div>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<img src={Img} alt='logo' style={{ width: '90%' }} />
											<div className='text-izq'>
												Generando soluciones <br />
												de impacto
											</div>
											<div>
												<span className='letter-purple'>Jueves 10 de junio&nbsp;</span>
												<span className='letter-yellow'>9am a 11am GMT-5</span>{' '}
											</div>
											<small style={{ color: '#fff' }}>*Consulta tu zona horaria</small>
										</Grid>

										<Grid item lg={6} md={6} sm={6} xs={12}>
											<div className='title'>
												Participa en esta experiencia digital y forma parte de los líderes que están generando más impacto en la transformación de la
												industria.
											</div>
											<TextField
												value={data.nombre}
												onChange={(e) => setData({ ...data, nombre: e.target.value })}
												required
												fullWidth
												variant='outlined'
												margin='normal'
												label='Nombre completo'
											/>
											<TextField
												value={data.rol}
												onChange={(e) => setData({ ...data, rol: e.target.value })}
												required
												fullWidth
												variant='outlined'
												margin='normal'
												label='Rol'
											/>
											<TextField
												value={data.numSocio}
												onChange={(e) => setData({ ...data, numSocio: e.target.value })}
												type='number'
												required
												fullWidth
												variant='outlined'
												margin='normal'
												label='Número de socio'
											/>
											<TextField
												value={data.gerenciaZona}
												onChange={(e) => setData({ ...data, gerenciaZona: e.target.value })}
												required
												fullWidth
												variant='outlined'
												margin='normal'
												label='Gerencia de zona'
											/>
											<div className='full center'>
												<button type='submit' className='mt-20 center texto-btn-login full'>
													REGISTRARME
												</button>
											</div>
										</Grid>
									</Grid>
								</form>
							</>
						) : (
							<>
								<form id='form' className='form-ingreso' noValidate onSubmit={ChecarEmail}>
									<Grid container direction='row' justify='center' alignItems='center' spacing={3}>
										<Grid item lg={12} md={12} sm={12} xs={12}>
											<div className='full center'>
												<img src={Logo} className='logoss' alt='logo' style={{ marginBottom: '30px' }} />
											</div>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<img src={Img} alt='logo' style={{ width: '90%' }} />
											<div className='text-izq'>
												Generando soluciones <br />
												de impacto
											</div>
											<div>
												<span className='letter-purple'>Jueves 10 de junio&nbsp;</span>
												<span className='letter-yellow'>9am a 11am GMT-5</span>{' '}
											</div>
											<small style={{ color: '#fff' }}>*Consulta tu zona horaria</small>
										</Grid>
										<Grid item lg={6} md={6} sm={6} xs={12}>
											<div className='title'>Ingresa tu correo para poder acceder</div>
											<TextField
												value={data.email}
												onChange={(e) => setData({ ...data, email: e.target.value })}
												type='email'
												required
												fullWidth
												variant='outlined'
												margin='normal'
												label='Correo electrónico'
												name='email'
											/>
											<div className='full center'>
												<button type='submit' className='mt-20 center texto-btn-login full'>
													INGRESAR
												</button>
												<div className='mt-30' style={{ cursor: 'pointer', color: '#fff' }} onClick={() => setRegistro(!registro)}>
													Regístrate
												</div>
											</div>
										</Grid>
									</Grid>
								</form>
							</>
						)}
					</Container>
				</div>
			</div>
		</>
	);
}
