import React, { useState } from "react";
import firebase from "../../../Config/firebase";
import { CssBaseline, TextField, Grid, Button, Container, Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
import ReactTooltip from "react-tooltip";
import Swal from "sweetalert2";
import uniqid from "uniqid";
import { getByValue, getDate } from "../../../scripts/functions";
import Modal from "../../../components/Modal";
import { isMobile } from "react-device-detect";
import infografia from "../../../Resources/pdf/proMasterRapid2022/infografia.pdf";
import popup from "../../../Resources/images/proMasterRapid2022/popup.png";
import popupMobile from "../../../Resources/images/proMasterRapid2022/popup-celular.png";
import img1 from "../../../Resources/images/proMasterRapid2022/img01.png";
import img2 from "../../../Resources/images/proMasterRapid2022/img02.png";
import BtnCerrar from "../../../Resources/images/proMasterRapid2022/btnCerrar.png";
import descarga from "../../../Resources/images/proMasterRapid2022/actividad/descarga.png";
import "./styles.scss";

export default function PacificaLogin(props) {
  const [privacidad, setPrivacidad] = useState(false);
  const [data, setData] = useState({});
  const [modal, setModal] = useState(true);
  const [inputOtro, setInputOtro] = useState(false);

  async function submitForm(e) {
    e.preventDefault();
    const { usuarioSet } = props;
    const date = getDate();
    const resultado = await getByValue("promasterRapid/usuarios", "email", data.email.toLowerCase());

    if (data.posicion === "" || data.email === "" || data.celular === "") {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Existen campos vacios",
      });
    } else {
      if (resultado) {
        usuarioSet(data.sid);
      } else {
        try {
          await firebase
          .database()
          .ref(`promasterRapid/usuarios/${uniqid()}`)
          .set({ data, fecha: date })
          .then(() => {
              usuarioSet(data.sid);
            }).catch(e => console.log(e));
        } catch (error) {
          console.log("====================================");
          console.log("error setDataBase: " + error);
          console.log("====================================");
        }
      }
    }
  }

  const descargarInfo = () => {
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute("download", "infografia");
    link.href = infografia;
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <>
      <div id="rapid-promaster-rapid">
        <div className="contenedor-form-wagoneer">
          <form className="form-ingreso bg-wagoneer" noValidate onSubmit={submitForm}>
            <Container component="main" maxWidth="md">
              <Grid container direction="row" spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <img src={img1} alt="title" className="full mb-60" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <img src={img2} alt="title" className="full mb-60" />
                </Grid>
              </Grid>
            </Container>
            <CssBaseline />
            <Container component="main" maxWidth="md">
              <Grid container direction="row" spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="celular" data-tip="*Nombre y apellido" data-iscapture="true" className="tooltip">
                      *Nombre y apellido
                    </a>
                    <ReactTooltip id="celular" place="top" type="light" effect="solid" multiline={true} />
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="nombre" value={data.nombre} onChange={(e) => setData({ ...data, nombre: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="codigoDistribuidor" data-tip="*Comienza con M y posteriormente, 4 dígitos." data-iscapture="true" className="tooltip">
                      Código de distribuidor
                    </a>
                    <ReactTooltip id="codigoDistribuidor" place="top" type="light" effect="solid" multiline={true} />
                  </div>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    id="codigoDistribuidor"
                    inputProps={{ maxLength: 5 }}
                    value={data.codigoDistribuidor}
                    onChange={(e) => setData({ ...data, codigoDistribuidor: e.target.value })}
                    label=""
                    SelectProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left tooltip">
                    <a data-tip data-for="global">
                      {" "}
                      SID/TID{" "}
                    </a>
                    <ReactTooltip id="global" aria-haspopup="true" role="example" place="top" type="light" effect="solid">
                      <p>SID * Es tu usuario en DealerCONNECT (6 dígitos)</p>
                      <p>TID * Empleado de Stellantis</p>
                    </ReactTooltip>
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="sid" inputProps={{ maxLength: 7 }} onChange={(e) => setData({ ...data, sid: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="main" data-tip="Código de distribuidor" data-iscapture="true" className="tooltip">
                      *E-mail corporativo
                    </a>
                  </div>
                  <TextField fullWidth variant="outlined" margin="normal" id="email" value={data.email} onChange={(e) => setData({ ...data, email: e.target.value })} label="" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="full left">
                    <a data-for="posicion" data-tip="Código de distribuidor" data-iscapture="true" className="tooltip">
                      *Posición
                    </a>
                  </div>
                  {!inputOtro && (
                    <TextField
                      select
                      label=""
                      fullWidth
                      value={data.posicion}
                      onChange={(e) => (e.target.value === "Otro" ? setInputOtro(true) : setData({ ...data, posicion: e.target.value }))}
                      SelectProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      margin="normal"
                      style={{ textAlign: "left" }}
                    >
                      <MenuItem value="Gerente General">Gerente General</MenuItem>
                      <MenuItem value="Gerente de Mercadotecnia">Gerente de Mercadotecnia</MenuItem>
                      <MenuItem value="Gerente de Ventas">Gerente de Ventas</MenuItem>
                      <MenuItem value="Gerente de Servicio">Gerente de Servicio</MenuItem>
                      <MenuItem value="Gerente de Refacciones">Gerente de Refacciones</MenuItem>
                      <MenuItem value="Brand Champion">Brand Champion</MenuItem>
                      <MenuItem value="Asesor de Ventas">Asesor de Ventas</MenuItem>
                      <MenuItem value="Asesor de Servicio">Asesor de Servicio</MenuItem>
                      <MenuItem value="Asesor de Refacciones">Asesor de Refacciones</MenuItem>
                      <MenuItem value="Admon. Garantias">Admon. Garantias</MenuItem>
                      <MenuItem value="Líder Customer Experience">Líder Customer Experience</MenuItem>
                      <MenuItem value="WEB Master">WEB Master</MenuItem>
                      <MenuItem value="Hostess">Hostess</MenuItem>
                      <MenuItem value="Stellantis Staff">Stellantis Staff</MenuItem>
                      <MenuItem value="Otro">Otro</MenuItem>
                    </TextField>
                  )}
                  {inputOtro && <TextField fullWidth variant="outlined" placeholder="Escribe tu posición" margin="normal" id="celular" value={data.posicion} onChange={(e) => setData({ ...data, posicion: e.target.value })} />}
                </Grid>
              </Grid>
            </Container>
            <Container component="main" maxWidth="md">
              <Grid container direction="row" spacing={2}>
                <Grid item lg={12} xs={12}>
                  <div className="full center">
                    <Button type="submit" className="mt-20 center btn-login-background">
                      <span className="texto-btn-login uppercase"></span>
                    </Button>
                  </div>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className="full center">
                    <Button className="mt-20 center" onClick={descargarInfo}>
                      <img src={descarga} alt="cerrar" />
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </form>
        </div>
      </div>
      <Modal open={modal}>
        <div className="instrucciones-pro-master-rapid">
          <div className="full mb-30" style={{ textAlign: "right" }}>
            <Button className="mt-20 center" onClick={() => setModal(false)}>
              <img src={BtnCerrar} alt="cerrar" style={{ width: "40px" }} />
            </Button>
          </div>
          {isMobile && (
            <div>
              <img src={popupMobile} alt="popup" className="full mt-30" style={{ padding: "10px" }} />
            </div>
          )}
          {!isMobile && <img src={popup} alt="popup" className="full mt-30" />}
          {/* <img src={popupMobile} alt="popup" className="full mt-30" /> */}
        </div>
      </Modal>
    </>
  );
}
