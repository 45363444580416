import React, { useState } from "react";
import firebase from "../../../Config/firebase";
import { TextField, Grid, Button, Container, MenuItem, CssBaseline } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import uniqid from "uniqid";
import Swal from "sweetalert2";
import { getByValue, getDate } from "../../../scripts/functions";
import Header from "../../../components/Header";
import Btn from "../../../Resources/images/heineken/btn02.png";
import Logo from "../../../Resources/images/heineken2/Logo.png";
import titulo from "../../../Resources/images/heineken2/Titulo.png";
import BtnImg from "../../../Resources/images/heineken2/btn01.png";
// import CYAImage from '../../../Resources/images/Heineken/img-login.png';
import "./styles.scss";

export default function CyaLogin(props) {
  const [data, setData] = useState({ area: "Reparto", region: "Centro" });
  const [alerta, setAlerta] = useState(false);
  const { usuarioSet } = props;

  async function validar(e) {
    e.preventDefault();
    if (!data.nombre || !data.numSocio) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Existen campos vacios",
      });
    } else {
      const resultado = await getByValue("heineken/usuarios", "numSocio", data.numSocio);
      console.log("====================================");
      console.log(resultado);
      console.log("====================================");
      if (resultado) {
        try {
          await firebase
            .database()
            .ref(`heineken/usuarios/${data.numSocio}`)
            .update({ ultimaConexion: getDate() })
            .then(async () => {
              usuarioSet(data.numSocio);
            });
        } catch (error) {
          console.log("====================================");
          console.log("error setDataBase: " + error);
          console.log("====================================");
        }
      } else {
        try {
          await firebase
            .database()
            .ref(`heineken/usuarios/${data.numSocio}`)
            .set({ ...data, ultimaConexion: getDate() })
            .then(async () => {
              const busqueda = await getByValue("heineken/usuarios", "numSocio", data.numSocio);
              if (busqueda) {
                usuarioSet(data.numSocio);
              } else {
                Swal.fire({
                  icon: "error",
                  title: "¡Error!",
                  text: "Error de RED , contacta a un administrador",
                });
              }
            });
        } catch (error) {
          console.log("====================================");
          console.log("error setDataBase: " + error);
          console.log("====================================");
        }
      }
    }
  }

  return (
    <>
      <Header logo={Logo} clase="header-heineken" />
      <div id="heineken">
        <div className="contenedor-form-heineken">
          <Container component="main" maxWidth="md">
            <Grid container direction="row">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="center">
                  <img src={titulo} alt="title" className="mt-30" style={{ width: "300px", marginBottom: "20px" }} />
                </div>
              </Grid>
            </Grid>
            <CssBaseline />
            <form className="form-ingreso bg-heineken" noValidate onSubmit={validar}>
              <div className=" bg-heineken">
                <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth InputLabelProps={{ shrink: true }} variant="outlined" margin="normal" label="Nombre" value={data.nombre} onChange={(e) => setData({ ...data, nombre: e.target.value })} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField fullWidth InputLabelProps={{ shrink: true }} variant="outlined" margin="normal" label="Número de socio" value={data.numSocio} onChange={(e) => setData({ ...data, numSocio: e.target.value })} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField select label="Área" fullWidth value={data.area} onChange={(e) => setData({ ...data, area: e.target.value })} variant="outlined" margin="normal" style={{ textAlign: "left" }}>
                      <MenuItem value="Reparto">Reparto</MenuItem>
                      <MenuItem value="Ventas">Ventas</MenuItem>
                      <MenuItem value="Six">Six</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField select label="Región" fullWidth value={data.region} onChange={(e) => setData({ ...data, region: e.target.value })} variant="outlined" margin="normal" style={{ textAlign: "left" }}>
                      <MenuItem value="Centro">Centro</MenuItem>
                      <MenuItem value="Sureste">Sureste</MenuItem>
                      <MenuItem value="Occidente">Occidente</MenuItem>
                      <MenuItem value="Noreste">Noreste</MenuItem>
                      <MenuItem value="Noroeste">Noroeste</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="center full">
                      <Button type="submit">
                        <img src={BtnImg} alt="as" style={{ width: "250px" }} />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          </Container>
        </div>
      </div>
    </>
  );
}
